import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import InputLabel from '@mui/material/InputLabel'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import OutlinedInput from '@mui/material/OutlinedInput'
import { withFirebase } from 'react-redux-firebase'

function PlayerAdd({ open, onClose, firebase: { push } }) {
  const [openDialog, setOpenDialog] = React.useState(open)
  const [firstname, setFirstname] = React.useState('')
  const [lastname, setLastname] = React.useState('')
  const [phone, setPhone] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [gender, setGender] = React.useState('')
  const [rating, setRating] = React.useState('')


  
  React.useEffect(() => {
    setOpenDialog(open)
  }, [setOpenDialog, open])

  const handleDialogClose = () => {
    // setOpenDialog(false)
    setFirstname('')
    setLastname('')
    setPhone('')
    setEmail('')
    setGender('')
    setRating('')
    onClose()
  }

  const onFirstNameChange = (event) => {
    setFirstname(event.target.value)
  }

  const onLastNameChange = (event) => {
    setLastname(event.target.value)
  }

  const onPhoneChange = (event) => {
    setPhone(event.target.value)
  }

  const onEmailChange = (event) => {
    setEmail(event.target.value)
  }

  const onGenderChange = (event) => {
    setGender(event.target.value)
  }

  const onRatingChange = (event) => {
    setRating(event.target.value)
  }

  const handlePlayerSave = () => {
    const player = { firstname, lastname, phone, gender, email, rating }
    push('customers/lahiliiga/players', player)
    onClose()
  }

  return (
    <Dialog fullWidth={true} maxWidth={'sm'} open={openDialog} onClose={handleDialogClose}>
      <DialogTitle>Lisää pelaaja</DialogTitle>
      <DialogContent>
        <FormControl sx={{ mt: 2, minWidth: 10 }}>
          <InputLabel htmlFor='outlined-adornment-amount'>Etunimi</InputLabel>
          <OutlinedInput
            id='outlined-adornment-amount'
            value={firstname}
            onChange={onFirstNameChange}
            aria-describedby='outlined-weight-helper-text'
            inputProps={{
              'aria-label': 'etunimi',
            }}
            label='Etunimi'
          />
        </FormControl>

        <FormControl sx={{ mt: 2, minWidth: 10 }}>
          <InputLabel htmlFor='outlined-adornment-amount'>Sukunimi</InputLabel>
          <OutlinedInput
            id='outlined-adornment-amount'
            value={lastname}
            onChange={onLastNameChange}
            aria-describedby='outlined-weight-helper-text'
            inputProps={{
              'aria-label': 'sukunimi',
            }}
            label='Sukunimi'
          />
        </FormControl>

        <FormControl sx={{ mt: 2, minWidth: 10 }}>
          <InputLabel htmlFor='outlined-adornment-amount'>Puhelin</InputLabel>
          <OutlinedInput
            id='outlined-adornment-amount'
            value={phone}
            onChange={onPhoneChange}
            aria-describedby='outlined-weight-helper-text'
            inputProps={{
              'aria-label': 'puhelin',
            }}
            label='Puhelin'
          />
        </FormControl>

        <FormControl sx={{ mt: 2, minWidth: 10 }}>
          <InputLabel htmlFor='outlined-adornment-amount'>Email</InputLabel>
          <OutlinedInput
            id='outlined-adornment-amount'
            value={email}
            onChange={onEmailChange}
            aria-describedby='outlined-weight-helper-text'
            inputProps={{
              'aria-label': 'email',
            }}
            label='Email'
          />
        </FormControl>

        <FormControl sx={{ mt: 2, minWidth: 10 }}>
          <InputLabel htmlFor='outlined-adornment-amount'>Taso</InputLabel>
          <OutlinedInput
            id='outlined-adornment-amount'
            value={rating}
            onChange={onRatingChange}
            aria-describedby='outlined-weight-helper-text'
            inputProps={{
              'aria-label': 'rating',
            }}
            label='Taso'
          />
        </FormControl>
        
        <FormControl sx={{ mt: 2, minWidth: 120 }}>
          <InputLabel htmlFor='max-width'>Sukupuoli</InputLabel>
          <Select
            value={gender}
            onChange={onGenderChange}
            label='Sukupuoli'
            inputProps={{
              name: 'max-width',
              id: 'max-width',
            }}
          >
            <MenuItem value='men'>Mies</MenuItem>
            <MenuItem value='women'>Nainen</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose}>Sulje</Button>
        <Button onClick={() => handlePlayerSave()}>Tallenna</Button>
      </DialogActions>
    </Dialog>
  )
}

export default withFirebase(PlayerAdd)