import * as React from 'react'
import { useTheme } from '@mui/material/styles'
import { ColorModeContext } from './theme-context'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Chip from '@mui/material/Chip'
import { styled } from '@mui/material/styles'
import Navigation from './Navigation'
import Box from '@mui/material/Box'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { connect } from 'react-redux'
import { firebaseConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import DatePicker from '@mui/lab/DatePicker'
import TextField from '@mui/material/TextField'
import { format, parseISO, isSameDay, isAfter } from 'date-fns'
import { fi } from 'date-fns/locale'
import { getTieScores } from './Scores'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import fiLocale from 'date-fns/locale/fi'
import { filterGroup, filterTeam } from './StateFilters'

const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}))

const TieContainer = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
}))

function LandingPage({ fixtures, locations, nextDay }) {
  useTheme()
  React.useContext(ColorModeContext)
  const [selectedDate, setSelectedDate] = React.useState(format(nextDay, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"))

  React.useEffect(() => {
    if (nextDay) {
      setSelectedDate(format(nextDay, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"))
    }
  }, [nextDay])

  const renderDateTies = () => {
    // console.log(typeof selectedDate)
    return fixtures.filter((x) => isSameDay(new Date(selectedDate), parseISO(x.value.time))).map((f) => renderTie(f))
  }

  const renderTie = (tie) => {
    const location = tie.value.location ? locations.find((l) => l.key === tie.value.location) : null
    let score = null
    if (tie.value.rubbers && tie.value.rubbers.length > 0 && tie.value.rubbers[0].score) {
      const { homepoints, awaypoints } = getTieScores(tie.value.rubbers, tie.value.sport)
      score = (
        <Typography ml={2} color={'lightgreen'} variant='subtitle1' component='span'>
          {homepoints} - {awaypoints}
        </Typography>
      )
    }
    return (
      <TieContainer sx={{ mb: 1 }}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <Typography variant='h6' component='span'>
                  {tie.value.home.name} - {tie.value.away.name}
                </Typography>
                {score}
              </Grid>
              <Grid item xs={4} sm={2} md={2}>
                <Typography variant='body2' component='div'>
                  {tie.value.time
                    ? format(parseISO(tie.value.time), 'eee d.M.yyyy p', {
                        locale: fi,
                      })
                    : null}
                </Typography>
              </Grid>
              <Grid item xs={4} sm={2} md={2}>
                <Typography variant='body2' component='div'>
                  {location ? location.value.name : null} - Kierros {tie.value.round + 1}
                </Typography>
              </Grid>
              {tie.value.note ? (
                <Grid item xs={12}>
                  <Chip label={tie.value.note} size='small' />
                </Grid>
              ) : null}
            </Grid>
          </AccordionSummary>
          <AccordionSummary>
            <Grid item>
              <TableContainer component={Paper}>
                <Table sx={{ border: 0 }} size='small' aria-label='a dense table'>
                  <TableBody>
                    {tie.value.rubbers ? tie.value.rubbers
                      .sort((a, b) => a.order - b.order)
                      .map((r) => (
                        <TableRow sx={{ td: { border: 0 } }}>
                          <TableCell>
                            {r.court} - {r.time ? format(parseISO(r.time), 'p', { locale: fi }) : null}
                          </TableCell>
                          <TableCell align='right'>
                            {r.home1 && r.home1.id.length > 0
                              ? r.type === '2p'
                                ? r.home1.name + ' - ' + r.away1.name
                                : r.home1.name + ' / ' + r.home2.name + ' - ' + r.away1.name + ' / ' + r.away2.name
                              : r.type === '2p'
                              ? 'Kaksinpeli'
                              : 'Nelinpeli'}
                          </TableCell>
                          <TableCell>
                            {r.score
                              ? r.score
                                  .map((set) => {
                                    return set.home + '-' + set.away
                                  })
                                  .join(', ')
                              : null}
                          </TableCell>
                        </TableRow>
                      )) : null}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </AccordionSummary>
        </Accordion>
      </TieContainer>
    )
  }

  return (
    <>
      <Box
        sx={{
          width: '100vw',
          minHeight: '100vh',
          height: '100%',
          bgcolor: 'background.default',
          pb: 10,
        }}
      >
        <Navigation />

        {/* <Box
        sx={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          bgcolor: 'background.default',
          color: 'text.primary',
          borderRadius: 1,
          p: 3,
        }}
      >
        {theme.palette.mode} mode
        <IconButton sx={{ ml: 1 }} onClick={colorMode.toggleColorMode} color='inherit'>
          {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
        </IconButton>
      </Box> */}
        <Grid container spacing={2} mb={1}>
          <Grid item xs={12}>
            <Item>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={fiLocale}>
                <DatePicker
                  label='Pelipäivä'
                  value={selectedDate || new Date()}
                  onChange={(newValue) => {
                    setSelectedDate(newValue)
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Item>
          </Grid>
        </Grid>
        <Grid container spacing={1} direction='row' justifyContent='center' alignItems='center'>
          <Grid item xs={12} md={7} spacing={1}>
            {renderDateTies()}
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

const withTeams = compose(
  firebaseConnect(() => [
    {
      path: 'customers/lahiliiga/tournaments/teams',
    },
    {
      path: 'customers/lahiliiga/tournaments/fixtures',
    },
    {
      path: 'customers/lahiliiga/locations',
    },
    {
      path: 'customers/lahiliiga/players',
    },
  ]),
  connect((state, props) => {
    if (
      state.firebase.ordered.customers &&
      state.firebase.ordered.customers.lahiliiga &&
      state.firebase.ordered.customers.lahiliiga.locations &&
      state.firebase.ordered.customers.lahiliiga.players &&
      state.firebase.ordered.customers.lahiliiga.tournaments &&
      state.firebase.ordered.customers.lahiliiga.tournaments.teams
    ) {
      const _groups = state.firebase.ordered.customers.lahiliiga.tournaments.teams
        .filter((t) => filterGroup(t, state))
        .map((t) => t.value.group)
        .filter((v, i, a) => a.indexOf(v) === i)
        .filter((x) => x)
        .sort()

      const _teams = state.firebase.ordered.customers.lahiliiga.tournaments.teams.filter((t) => {
        const ok = filterTeam(t, state)
        // if (ok) console.log(t, state)
        return ok
      })
      // console.log('_teams', _teams)
      let nextDay = new Date()
      let _fixtures = []
      if (state.firebase.ordered.customers.lahiliiga.tournaments.fixtures) {
        _fixtures = state.firebase.ordered.customers.lahiliiga.tournaments.fixtures.filter((t) => {
          return t.value.sport === state.sport.sport.toLowerCase() && t.value.time
        })
        _fixtures.sort((a, b) => {
          if (a.value.time && b.value.time) {
            return parseISO(a.value.time) - parseISO(b.value.time)
          } else if (a.value.time) {
            return -1
          } else if (b.value.time) {
            return 1
          } else {
            return a.value.round - a.value.round
          }
        })
        const now = new Date()
        const yesterday = now.setDate(now.getDate() - 1)
        // console.log(yesterday)
        const nextDayFixture = _fixtures.find((x) => isAfter(parseISO(x.value.time), yesterday))
        // console.log(nextDayFixture)
        if (nextDayFixture) nextDay = parseISO(nextDayFixture.value.time)
      }

      return {
        players: state.firebase.ordered.customers.lahiliiga.players.map((r) => {
          return { id: r.key, ...r.value }
        }),
        fixtures: _fixtures,
        teams: _teams,
        groups: _groups,
        auth: state.firebase.auth,
        update: props.firebase.update,
        push: props.firebase.push,
        dispatch: props.firebase.dispatch,
        remove: props.firebase.remove,
        selections: state.sport,
        locations: state.firebase.ordered.customers.lahiliiga.locations,
        nextDay,
      }
    } else {
      return {
        players: [],
        fixtures: [],
        teams: [],
        groups: [],
        auth: state.firebase.auth,
        update: props.firebase.update,
        push: props.firebase.push,
        dispatch: props.firebase.dispatch,
        remove: props.firebase.remove,
        selections: state.sport,
        locations: [],
        nextDay: new Date(),
      }
    }
  })
)

export default withTeams(LandingPage)
