import React, { useState } from 'react'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { useFirebase } from 'react-redux-firebase'
import { useHistory } from 'react-router-dom'
import Alert from '@mui/material/Alert'
import IconButton from '@mui/material/IconButton'
import Collapse from '@mui/material/Collapse'
import CloseIcon from '@mui/icons-material/Close'

function Copyright(props) {
  return (
    <Typography variant='body2' color='text.secondary' align='center' {...props}>
      {'Copyright © '}
      <Link color='inherit' href='https://www.cintoia.com/'>
        Cintoia
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}

const theme = createTheme()

export default function SignIn() {
  const firebase = useFirebase()
  let history = useHistory()
  const [loginErrorMessage, setLoginErrorMessage] = useState(null)
  const [errorModalOpen, setErrorModalOpen] = useState(false)
  const errorToggle = (state) => setErrorModalOpen(state)

  const handleSubmit = (event) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    // eslint-disable-next-line no-console
    // console.log({
    //   email: data.get('email'),
    //   password: data.get('password'),
    // })
    firebase
      .auth()
      .signInWithEmailAndPassword(data.get('email'), data.get('password'))
      .then((reset) => {
        // console.log('Logged in')
        history.push('/')
      })
      .catch((error) => {
        console.log(error)
        if (error.code === 'auth/user-disabled') {
          setLoginErrorMessage('Tunnus disabloitu ylläpidon toimesta. Ota yhteyttä asiakaspalveluun.')
        } else if (error.code === 'auth/wrong-password') {
          setLoginErrorMessage('Väärä salasana')
        } else if (error.code === 'auth/too-many-requests') {
          setLoginErrorMessage(
            'Liian paljon sisäänkirjautumisyrityksiä. Tunnus poistettu käytöstä turvallisuussyistä. Voit ottaa tunnuksen käyttöön nollaamalla salasanan tai kokeilemalla hetken päästä uudelleen.'
          )
        } else if (error.code === 'auth/invalid-email') {
          setLoginErrorMessage('Vääränmuotoinen sähköpostiosoite')
        } else if (error.code === 'auth/user-not-found') {
          setLoginErrorMessage('Ei löydy käyttäjää')
        } else {
          setLoginErrorMessage('Tuntematon virhe sisäänkirjauksessa.')
        }
        errorToggle(true)
        // console.log(error)
      })
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component='main' maxWidth='xs'>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component='h1' variant='h5'>
            Sign in
          </Typography>
          <Box component='form' onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin='normal'
              required
              fullWidth
              id='email'
              label='Email Address'
              name='email'
              autoComplete='email'
              autoFocus
            />
            <TextField
              margin='normal'
              required
              fullWidth
              name='password'
              label='Password'
              type='password'
              id='password'
              autoComplete='current-password'
            />
            {/* <FormControlLabel control={<Checkbox value='remember' color='primary' />} label='Remember me' /> */}
            <Button type='submit' fullWidth variant='contained' sx={{ mt: 3, mb: 2 }}>
              Sign In
            </Button>
            {/* <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid> */}
          </Box>
          <Box sx={{ width: '100%' }}>
            <Collapse in={errorModalOpen}>
              <Alert
                severity="error"
                action={
                  <IconButton
                    aria-label='close'
                    color='inherit'
                    size='small'
                    onClick={() => {
                      errorToggle(false)
                    }}
                  >
                    <CloseIcon fontSize='inherit' />
                  </IconButton>
                }
                sx={{ mb: 2 }}
              >
                {loginErrorMessage}
              </Alert>
            </Collapse>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  )
}
