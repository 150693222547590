const filterLeagueTeams = (t, state) => {
  if (t.value.leagueid) {
    return t.value.leagueid === state.sport?.league?.key && t.value.season === state.sport.season
  }
}

const filterTeam = (t, state) => {
  // console.log('t', t)
  // console.log('state', state.sport)
  if (t.value.leagueid) {
    if (state.sport.group) {
      return (
        t.value.leagueid === state.sport?.league?.key &&
        t.value.gender === state.sport.gender &&
        t.value.season === state.sport.season &&
        t.value.group === state.sport.group &&
        t.value.division === state.sport.division
      )
    } else {
      return (
        t.value.leagueid === state.sport?.league?.key &&
        t.value.gender === state.sport.gender &&
        t.value.season === state.sport.season &&
        t.value.division === state.sport.division
      )
    }
  } else if (t.value.sport) {
    if (state.sport.group) {
      return (
        t.value.sport === state.sport.sport.toLowerCase() &&
        t.value.gender === state.sport.gender &&
        t.value.season === state.sport.season &&
        t.value.group === state.sport.group &&
        t.value.division === state.sport.division
      )
    } else {
      // console.log('filtering here')
      return (
        t.value.sport === state.sport.sport.toLowerCase() &&
        t.value.gender === state.sport.gender &&
        t.value.season === state.sport.season &&
        t.value.division === state.sport.division
      )
    }
  }
}

const filterFixture = (t, state) => {
  if(t.value.gender === 'men' && t.value.sport === 'padel' && t.value.season === '2023-syksy') console.log('fixture k', t)
  // if(t.value.leagueid === '-Mwf7h65i7mpoD19VwPX') console.log('fixture', t)
  if (t.value.leagueid) {
    if (state.sport.group) {
      return (
        t.value.leagueid === state.sport?.league?.key &&
        t.value.gender === state.sport.gender &&
        t.value.season === state.sport.season &&
        t.value.group === state.sport.group &&
        t.value.division === state.sport.division
      )
    } else {
      return (
        t.value.leagueid === state.sport?.league?.key &&
        t.value.gender === state.sport.gender &&
        t.value.season === state.sport.season &&
        t.value.division === state.sport.division
      )
    }
  } else if (t.value.sport) {
    if (state.sport.group) {
      return (
        t.value.sport === state.sport.sport.toLowerCase() &&
        t.value.gender === state.sport.gender &&
        t.value.season === state.sport.season &&
        t.value.group === state.sport.group &&
        t.value.division === state.sport.division
      )
    } else {
      return (
        t.value.sport === state.sport.sport.toLowerCase() &&
        t.value.gender === state.sport.gender &&
        t.value.season === state.sport.season &&
        t.value.division === state.sport.division
      )
    }
  }
}

const filterGroup = (t, state) => {
  if (t.value.sport) {
    return (
      t.value.sport === state.sport.sport.toLowerCase() &&
      t.value.gender === state.sport.gender &&
      t.value.season === state.sport.season &&
      t.value.division === state.sport.division
    )
  } else {
    return (
      t.value.leagueid === state.sport?.league?.key &&
      t.value.gender === state.sport.gender &&
      t.value.season === state.sport.season &&
      t.value.division === state.sport.division
    )
  }
}

export { filterTeam, filterFixture, filterGroup, filterLeagueTeams }
