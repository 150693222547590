import * as React from 'react'
import Paper from '@mui/material/Paper'
// import Table from '@mui/material/Table'
// import TableHead from '@mui/material/TableHead'
// import TableBody from '@mui/material/TableBody'
// import TableCell from '@mui/material/TableCell'
// import TableRow from '@mui/material/TableRow'
import { styled } from '@mui/material/styles'

// import EditIcon from '@mui/icons-material/EditOutlined'
// import DoneIcon from '@mui/icons-material/DoneAllTwoTone'
// import RevertIcon from '@mui/icons-material/NotInterestedOutlined'
// import IconButton from '@mui/material/IconButton'
// import Input from '@mui/material/Input'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Navigation from './Navigation'
import { Container } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
// import FormControlLabel from '@mui/material/FormControlLabel'
// import Radio from '@mui/material/Radio'
// import RadioGroup from '@mui/material/RadioGroup'

import { connect } from 'react-redux'
import { firebaseConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import LeagueAdd from './LeagueAdd'
import { genders, divisions } from './config'

const dataGridColumns = [
  { field: 'division', headerName: 'Sarjataso', type: 'singleSelect', valueOptions: divisions.map((x) => ({ value: x.key, label: x.title })), flex: 1, editable: true },
  { field: 'gender', headerName: 'Sukupuoli', type: 'singleSelect', valueOptions: genders.map((x) => ({ value: x.key, label: x.title })), flex: 1, editable: true },
  { field: 'description', headerName: 'Info', flex: 6, editable: true },
]

// const CustomTableCell = ({ row, name, onChange }) => {
//   const { isEditMode } = row
//   return (
//     <TableCell align='left'>
//       {isEditMode ? (
//         name === 'gender' ? (
//           <RadioGroup
//             aria-label='gender'
//             defaultValue='women'
//             name='gender'
//             value={row[name] || null}
//             onChange={(e) => onChange(e, row)}
//           >
//             <FormControlLabel value='women' control={<Radio />} label='Nainen' />
//             <FormControlLabel value='men' control={<Radio />} label='Mies' />
//           </RadioGroup>
//         ) : (
//           <Input value={row[name]} name={name} onChange={(e) => onChange(e, row)} />
//         )
//       ) : (
//         row[name]
//       )}
//     </TableCell>
//   )
// }

const ThemeButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.secondary,
}))

function RegulationsPage({ league, regulations, push, update }) {
  const [rows, setRows] = React.useState(regulations || [])
  // const [previous, setPrevious] = React.useState({})
  const [regulationInsertOpen, setRegulationInsertOpen] = React.useState(false)

  // const onToggleEditMode = (id) => {
  //   setRows((state) => {
  //     return rows.map((row) => {
  //       if (row.id === id) {
  //         if (row.isEditMode) {
  //           const updateRow = { ...row }
  //           delete updateRow.isEditMode
  //           update('customers/lahiliiga/configs/leagues/' + id, updateRow)
  //         }
  //         return { ...row, isEditMode: !row.isEditMode }
  //       }
  //       return row
  //     })
  //   })
  // }

  const onCellEditCommit = (params) => {
    const updateRow = { [params.field]: params.value }
    update('customers/lahiliiga/tournaments/regulations/' + params.id, updateRow)
  }

  const onAddInfo = () => {
    const pushData = { leagueid: league.key }
     push('customers/lahiliiga/tournaments/regulations/', pushData)
  }


  React.useEffect(() => {
    setRows(regulations)
  }, [regulations])

  // const onChange = (e, row) => {
  //   console.log(e.target.value, e.target.name, row)
  //   if (!previous[row.id]) {
  //     setPrevious((state) => ({ ...state, [row.id]: row }))
  //   }
  //   const value = e.target.value
  //   const name = e.target.name
  //   const { id } = row
  //   const newRows = rows.map((row) => {
  //     if (row.id === id) {
  //       return { ...row, [name]: value }
  //     }
  //     return row
  //   })
  //   setRows(newRows)
  // }

  // const onRevert = (id) => {
  //   const newRows = rows.map((row) => {
  //     if (row.id === id) {
  //       return previous[id] ? previous[id] : row
  //     }
  //     return row
  //   })
  //   setRows(newRows)
  //   setPrevious((state) => {
  //     delete state[id]
  //     return state
  //   })
  //   onToggleEditMode(id)
  // }

  // const addNewRegulation = () => {
  //   setRegulationInsertOpen(true)
  // }
  const closeRegulationAdd = () => {
    setRegulationInsertOpen(false)
  }

  
  return (
    <>
      <Box
        sx={{
          width: '100vw',
          minHeight: '100vh',
          height: '100%',
          bgcolor: 'background.default',
          pb: 10,
        }}
      >
        <Navigation />
        <Container style={{ marginTop: 20 }}>
          <Paper>
            <ThemeButton onClick={() => onAddInfo()}>
              <AddIcon />
              Lisää divisoonan info
            </ThemeButton>

            <DataGrid
              autoHeight
              pagination
              rows={rows}
              columns={dataGridColumns}
              components={{ Toolbar: GridToolbar }}
              initialState={{
                pagination: {
                  pageSize: 25,
                },
              }}
              onCellEditCommit={onCellEditCommit}
            />

            {/* <Table aria-label='caption table'>
            <TableHead>
              <TableRow>
                <TableCell align='left'>
                  {' '}
                  <Fab color='secondary' aria-label='add' size='small' onClick={() => addEmptyRow()}>
                    <AddIcon />
                  </Fab>
                </TableCell>
                <TableCell align='left'>Etunimi</TableCell>
                <TableCell align='left'>Sukunimi</TableCell>
                <TableCell align='left'>Sukupuoli</TableCell>
                <TableCell align='left'>Puhelin</TableCell>
                <TableCell align='left'>Email</TableCell>
                <TableCell align='left'>Taso</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>
                    {row.isEditMode ? (
                      <>
                        <IconButton aria-label='done' onClick={() => onToggleEditMode(row.id)}>
                          <DoneIcon />
                        </IconButton>
                        <IconButton aria-label='revert' onClick={() => onRevert(row.id)}>
                          <RevertIcon />
                        </IconButton>
                      </>
                    ) : (
                      <IconButton aria-label='delete' onClick={() => onToggleEditMode(row.id)}>
                        <EditIcon />
                      </IconButton>
                    )}
                  </TableCell>
                  <CustomTableCell {...{ row, name: 'firstname', onChange }} />
                  <CustomTableCell {...{ row, name: 'lastname', onChange }} />
                  <CustomTableCell {...{ row, name: 'gender', onChange }} />
                  <CustomTableCell {...{ row, name: 'email', onChange }} />
                  <CustomTableCell {...{ row, name: 'phone', onChange }} />
                  <CustomTableCell {...{ row, name: 'rating', onChange }} />
                </TableRow>
              ))}
            </TableBody>
          </Table> */}
          </Paper>
        </Container>
      </Box>
      <LeagueAdd open={regulationInsertOpen} onClose={closeRegulationAdd} />
    </>
  )
}



const withPlayers = compose(
  firebaseConnect(() => [
    {
      path: 'customers/lahiliiga/tournaments/regulations',
    },
  ]),
  connect((state, props) => {
    if (state.firebase.ordered?.customers?.lahiliiga?.tournaments?.regulations && state.sport?.league?.key) {
      return {
        regulations: state.firebase.ordered.customers.lahiliiga.tournaments.regulations.map((r) => {
          return { id: r.key, ...r.value }
        }).filter((x) => x.leagueid === state.sport.league.key)
        ,
        auth: state.firebase.auth,
        update: props.firebase.update,
        push: props.firebase.push,
        set: props.firebase.set,
        league: state.sport.league
      }
    } else {
      return {
        regulations: [],
        auth: state.firebase.auth,
        update: props.firebase.update,
        push: props.firebase.push,
        set: props.firebase.set,
        league: state.sport.league
      }
    }
  })
)

export default withPlayers(RegulationsPage)
