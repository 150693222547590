import React from 'react'
// import HomeScreen from './screens/HomeScreen'
// import HeavyHomeScreen from './screens/HeavyHomeScreen'
// import LogScreen from './screens/LogScreen'
// import RegScreen from './screens/RegScreen'
// import ReservationScreen from './screens/ReservationScreen'
// import PastPaymentScreen from './screens/PastPaymentScreen'
// import ReservationsScreen from './screens/ReservationsScreen'
// import ProfileScreen from './screens/ProfileScreen'

// import SignUpScreen from './screens/SignUpScreen';
// import CheckoutScreen from './screens/CheckoutScreen';
// import ProfileScreen from './screens/ProfileScreen';
// import ProfileEditScreen from './screens/ProfileEditScreen';
// import SignOutScreen from './screens/SignOutScreen';

// import CourtReservationModal from './screens/CourtReservationModal'
// import ReservationDetailsScreen from './screens/ReservationDetailsScreen'
// import ReservationEditScreen from './screens/ReservationEditScreen'
// import ShopScreen from './screens/ShopScreen';

// import OtherScreen from './screens/OtherScreen'
// import ScheduleScreen from './screens/PreScheduleScreen'
// import ArticleScreen from './screens/ArticleScreen'
// import PaymentView from './screens/PaymentView'

// import { useSelector } from 'react-redux'
import { isLoaded, isEmpty } from 'react-redux-firebase'
import { connect } from 'react-redux'

// import AddNewScreen from './screens/AddNewScreen';
import { Switch, Route, Redirect } from 'react-router'
import LandingPage from './LandingPage'
import PlayersPage from './PlayersPage'
import FixturesPage from './FixturesPage'
import StandingsPage from './StandingsPage'
import SignIn from './SignIn'
import TeamsPage from './TeamsPage'
import LeaguesPage from './LeaguesPage'
import RegulationsPage from './RegulationsPage'
import SeasonsPage from './SeasonsPage'
// import SignInScreen from './SignInScreen'
// import LoginPage from './LoginPage';
// import logo from './assets/load_blug.png'

const enhance = connect(
  // Map redux state to component props
  ({ firebase: { auth, profile } }) => ({
    auth,
    profile,
  })
)

function PrivateRoute({ children, isLoggedIn, ...rest }) {
  // console.log('isLoggedIn', isLoggedIn)

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

const AppNavigator = ({ auth, profile }) => {
  const isLoggedIn = !isEmpty(auth)
  const authLoaded = isLoaded(auth)
  if (!authLoaded) {
    return <div style={{ flex: 1 }}>Loading...</div>
  } else {
    return (
      <Switch>
        <Route path='/login'>
          <SignIn />
        </Route>
        {/* <Route path='/signin'>
          <SignInScreen />
        </Route>
        <Route path='/register'>
          <RegScreen />
        </Route>
      */}
        <PrivateRoute path='/edit/teams' auth={auth} isLoggedIn={isLoggedIn}>
          <TeamsPage />
        </PrivateRoute>
        <PrivateRoute path='/fixtures' auth={auth} isLoggedIn={isLoggedIn}>
          <FixturesPage />
        </PrivateRoute>
        <PrivateRoute path='/standings' auth={auth} isLoggedIn={isLoggedIn}>
          <StandingsPage />
        </PrivateRoute>
        <PrivateRoute path='/teams' auth={auth} isLoggedIn={isLoggedIn}>
          <TeamsPage />
        </PrivateRoute>
        <PrivateRoute path='/players' auth={auth} isLoggedIn={isLoggedIn}>
          <PlayersPage />
        </PrivateRoute>
        <PrivateRoute path='/leagues' auth={auth} isLoggedIn={isLoggedIn}>
          <LeaguesPage />
        </PrivateRoute>
        <PrivateRoute path='/seasons' auth={auth} isLoggedIn={isLoggedIn}>
          <SeasonsPage />
        </PrivateRoute>
        <PrivateRoute path='/regulations' auth={auth} isLoggedIn={isLoggedIn}>
          <RegulationsPage />
        </PrivateRoute>
        <PrivateRoute path='/' auth={auth} isLoggedIn={isLoggedIn}>
          <LandingPage />
        </PrivateRoute>
      </Switch>
    )
  }
}

export default enhance(AppNavigator)
