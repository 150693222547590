import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import InputLabel from '@mui/material/InputLabel'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import OutlinedInput from '@mui/material/OutlinedInput'
import { withFirebase } from 'react-redux-firebase'
import { Grid } from '@mui/material'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'

function LeagueAdd({ open, onClose, firebase: { push } }) {
  const [openDialog, setOpenDialog] = React.useState(open)
  const [name, setName] = React.useState('')
  const [cost, setCost] = React.useState(0)
  const [sport, setSport] = React.useState('')
  const [costunit, setCostunit] = React.useState('')
  const [entrystart, setEntrystart] = React.useState('')
  const [entryseason, setEntryseason] = React.useState('')
  const [entryend, setEntryend] = React.useState('')
  const [regulations, setRegulations] = React.useState('')
  const [description, setDescription] = React.useState('')

  React.useEffect(() => {
    setOpenDialog(open)
  }, [setOpenDialog, open])

  const handleDialogClose = () => {
    // setOpenDialog(false)
    setName('')
    setCost(0)
    setSport('')
    setCostunit('')
    setEntrystart('')
    setEntryseason('')
    setEntryend('')
    setRegulations('')
    setDescription('')
    onClose()
  }

  const onNameChange = (event) => {
    setName(event.target.value)
  }

  const onCostChange = (event) => {
    setCost(event.target.value)
  }

  const onSportChange = (event) => {
    setSport(event.target.value)
  }

  const onCostunitChange = (event) => {
    setCostunit(event.target.value)
  }

  const onDescriptionChange = (event) => {
    setDescription(event.target.value)
  }

  const onRegulationsChange = (event) => {
    setRegulations(event.target.value)
  }

  const onEntryseasonChange = (event) => {
    setEntryseason(event.target.value)
  }

  const onEntrystartChange = (event) => {
    setEntrystart(event.target.value)
  }

  const onEntryendChange = (event) => {
    setEntryend(event.target.value)
  }

  const handleLeagueSave = () => {
    const league = {
      name: name,
      cost: cost,
      sport: sport,
      entrystart: entrystart,
      costunit: costunit,
      entryend: entryend,
      regulations: regulations,
      description: description,
      entryseason: entryseason  
    }
    push('customers/lahiliiga/configs/leagues', league)
    onClose()
  }

  return (
    <Dialog fullWidth={true} maxWidth={'lg'} open={openDialog} onClose={handleDialogClose}>
      <DialogTitle>Lisää sarja</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth sx={{ mt: 2, minWidth: 10 }}>
              <InputLabel htmlFor='outlined-adornment-amount'>Nimi</InputLabel>
              <OutlinedInput
                id='outlined-adornment-amount'
                value={name}
                onChange={onNameChange}
                aria-describedby='outlined-weight-helper-text'
                inputProps={{
                  'aria-label': 'nimi',
                }}
                fullWidth
                label='Nimi'
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl sx={{ mt: 2, minWidth: 120 }}>
              <InputLabel htmlFor='outlined-adornment-amount'>Laji</InputLabel>
              <Select
                value={sport}
                onChange={onSportChange}
                label='Laji'
                inputProps={{
                  name: 'max-width',
                  id: 'max-width',
                }}
              >
                <MenuItem value='tennis'>Tennis</MenuItem>
                <MenuItem value='padel'>Padel</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl sx={{ mt: 2, minWidth: 10 }}>
              <InputLabel htmlFor='outlined-adornment-amount'>Hinta</InputLabel>
              <OutlinedInput
                id='outlined-adornment-amount'
                value={cost}
                onChange={onCostChange}
                aria-describedby='outlined-weight-helper-text'
                inputProps={{
                  'aria-label': 'hinta',
                }}
                endAdornment={<InputAdornment position="start">€</InputAdornment>}
                label='Hinta'
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl sx={{ mt: 2, minWidth: 120 }}>
              <InputLabel htmlFor='max-width'>Hinnoittelu</InputLabel>
              <Select
                value={entrystart}
                onChange={onCostunitChange}
                label='Hinnoittelu'
                inputProps={{
                  name: 'max-width',
                  id: 'max-width',
                }}
              >
                <MenuItem value='pertie'>Per ottelu</MenuItem>
                <MenuItem value='perseason'>Per kausi</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth sx={{ mt: 2, minWidth: 400, minHeight: 150 }}>
            <TextField
                id='outlined-adornment-regulations'
                value={regulations}
                onChange={onRegulationsChange}
                multiline
                rows={4}
                maxRows={25}
                label='Säännöt'
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth sx={{ mt: 2, minWidth: 400, minHeight: 150 }}>
              {/* <InputLabel htmlFor='outlined-adornment-amount'>Esittely</InputLabel> */}
              <TextField
                id='outlined-adornment-description'
                value={description}
                onChange={onDescriptionChange}
                multiline
                rows={4}
                maxRows={25}
                label='Esittely'
              />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl sx={{ mt: 2, minWidth: 10 }}>
              <InputLabel htmlFor='outlined-adornment-amount'>Ilmoittautuminen alkaa</InputLabel>
              <OutlinedInput
                id='outlined-adornment-amount'
                value={entrystart}
                onChange={onEntrystartChange}
                aria-describedby='outlined-weight-helper-text'
                inputProps={{
                  'aria-label': 'Ilmoittautuminen alkaa',
                }}
                label='Ilmoittautuminen alkaa'
              />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl sx={{ mt: 2, minWidth: 10 }}>
              <InputLabel htmlFor='outlined-adornment-amount'>Ilmoittautuminen päättyy</InputLabel>
              <OutlinedInput
                id='outlined-adornment-amount'
                value={entryend}
                onChange={onEntryendChange}
                aria-describedby='outlined-weight-helper-text'
                inputProps={{
                  'aria-label': 'Ilmoittautuminen päättyy',
                }}
                label='Ilmoittautuminen päättyy'
              />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl sx={{ mt: 2, minWidth: 10 }}>
              <InputLabel htmlFor='outlined-adornment-amount'>Ilmoittautumiskausi</InputLabel>
              <OutlinedInput
                id='outlined-adornment-amount'
                value={entryseason}
                onChange={onEntryseasonChange}
                aria-describedby='outlined-weight-helper-text'
                inputProps={{
                  'aria-label': 'Ilmoittautumiskausi',
                }}
                label='Ilmoittautumiskausi'
              />
            </FormControl>
          </Grid>

        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose}>Sulje</Button>
        <Button onClick={() => handleLeagueSave()}>Tallenna</Button>
      </DialogActions>
    </Dialog>
  )
}

export default withFirebase(LeagueAdd)
