import * as React from 'react'
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles'
import { ColorModeContext } from './theme-context'
import { BrowserRouter } from 'react-router-dom'
import AppNavigator from './AppNavigator'
import { useCookies } from 'react-cookie'
// import { grey, deepOrange, amber } from '@mui/material/colors'

const getDesignTokens = (mode) => ({
  palette: {
    background: {
      light: '#ffffff',
      dark: '#000000',
      main: '#cccccc'
    },
    primary: {
      light: '#000000',
      main: '#ffffff',
      dark: '#000000',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
    mode,
  },
})


function App() {
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie] = useCookies(['sport', 'mode', 'favoriteteam'])
  const [mode, setMode] = React.useState(cookies.mode ? cookies.mode : 'dark')
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'))
      },
    }),
    []
  )

  // Update the theme only if the mode changes
  let theme = React.useMemo(() =>
    createTheme(getDesignTokens(mode)), [mode])

  theme = responsiveFontSizes(theme)

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
      <BrowserRouter>
        <AppNavigator />
        </BrowserRouter>
      </ThemeProvider>
    </ColorModeContext.Provider>
  )
}

export default App
