import * as React from 'react'
import Paper from '@mui/material/Paper'
// import Table from '@mui/material/Table'
// import TableHead from '@mui/material/TableHead'
// import TableBody from '@mui/material/TableBody'
// import TableCell from '@mui/material/TableCell'
// import TableRow from '@mui/material/TableRow'
import { styled } from '@mui/material/styles'

// import EditIcon from '@mui/icons-material/EditOutlined'
// import DoneIcon from '@mui/icons-material/DoneAllTwoTone'
// import RevertIcon from '@mui/icons-material/NotInterestedOutlined'
// import IconButton from '@mui/material/IconButton'
// import Input from '@mui/material/Input'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Navigation from './Navigation'
import { Container } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
// import FormControlLabel from '@mui/material/FormControlLabel'
// import Radio from '@mui/material/Radio'
// import RadioGroup from '@mui/material/RadioGroup'

import { connect } from 'react-redux'
import { firebaseConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import LeagueAdd from './LeagueAdd'
import EditorDialog from './Dialog'
// import { seasons } from './config'



// const CustomTableCell = ({ row, name, onChange }) => {
//   const { isEditMode } = row
//   return (
//     <TableCell align='left'>
//       {isEditMode ? (
//         name === 'gender' ? (
//           <RadioGroup
//             aria-label='gender'
//             defaultValue='women'
//             name='gender'
//             value={row[name] || null}
//             onChange={(e) => onChange(e, row)}
//           >
//             <FormControlLabel value='women' control={<Radio />} label='Nainen' />
//             <FormControlLabel value='men' control={<Radio />} label='Mies' />
//           </RadioGroup>
//         ) : (
//           <Input value={row[name]} name={name} onChange={(e) => onChange(e, row)} />
//         )
//       ) : (
//         row[name]
//       )}
//     </TableCell>
//   )
// }

const ThemeButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.secondary,
}))

function LeaguesPage({ leagues, push, update, seasons }) {
  const [rows, setRows] = React.useState(leagues || [])
  // const [previous, setPrevious] = React.useState({})
  const [leagueInsertOpen, setLeagueInsertOpen] = React.useState(false)
  const [editorOpen, setEditorOpen] = React.useState(false)
  const [selectedLeague, setSelectedLeague] = React.useState(null)

  const openRulesEditor = (id) => {
    const league = leagues.find((l) => l.id === id)
    // console.log('openRulesEditor', league)
    setSelectedLeague(league)
    setEditorOpen(true)
  }

  const dataGridColumns = [
    { field: 'name', headerName: 'Nimi', width: 300, editable: true },
    {
      field: 'sport', headerName: 'Laji', width: 40, editable: true,
      type: 'singleSelect',
      valueOptions: ['tennis', 'padel'],
    },
    { field: 'cost', headerName: 'Hinta', width: 40, editable: true, type: 'number' },
    // { field: 'costunit', headerName: 'Hinnoittelu', width: 150, editable: true },
    // { field: 'entrystart', headerName: 'Ilmo alkaa', width: 150, editable: true },
    { field: 'entryend', headerName: 'Ilmo päättyy', width: 150, editable: true, type: 'date', },
    {
      field: 'entryseason', headerName: 'Kausi', width: 150, editable: true,
      type: 'singleSelect',
      valueOptions: seasons.map((s) => s.key),
    },
    {
      field: 'state', headerName: 'Julkaistu', width: 150, editable: true,
      type: 'singleSelect',
      valueOptions: ['published', 'archived'],
    },
    {
      headerName: 'Muokkaa',
      field: 'edit',
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <Button
            onClick={() => {
              openRulesEditor(params.row.id)
            }}
          >Säännöt</Button>
        )
      },
    },
    {
      headerName: 'Avain',
      field: 'id',
      editable: false,
      width: 190,
    }
  ]

  const handleEditorClose = (content) => {
    if (content) {
      update('customers/lahiliiga/configs/leagues/' + selectedLeague.id, { regulations: content })
    }
    setEditorOpen(false)
  }

  // const onToggleEditMode = (id) => {
  //   setRows((state) => {
  //     return rows.map((row) => {
  //       if (row.id === id) {
  //         if (row.isEditMode) {
  //           const updateRow = { ...row }
  //           delete updateRow.isEditMode
  //           update('customers/lahiliiga/configs/leagues/' + id, updateRow)
  //         }
  //         return { ...row, isEditMode: !row.isEditMode }
  //       }
  //       return row
  //     })
  //   })
  // }

  const onCellEditCommit = (params) => {
    const updateRow = { [params.field]: params.value }
    update('customers/lahiliiga/configs/leagues/' + params.id, updateRow)
  }

  React.useEffect(() => {
    setRows(leagues)
  }, [leagues])

  // const onChange = (e, row) => {
  //   console.log(e.target.value, e.target.name, row)
  //   if (!previous[row.id]) {
  //     setPrevious((state) => ({ ...state, [row.id]: row }))
  //   }
  //   const value = e.target.value
  //   const name = e.target.name
  //   const { id } = row
  //   const newRows = rows.map((row) => {
  //     if (row.id === id) {
  //       return { ...row, [name]: value }
  //     }
  //     return row
  //   })
  //   setRows(newRows)
  // }

  // const onRevert = (id) => {
  //   const newRows = rows.map((row) => {
  //     if (row.id === id) {
  //       return previous[id] ? previous[id] : row
  //     }
  //     return row
  //   })
  //   setRows(newRows)
  //   setPrevious((state) => {
  //     delete state[id]
  //     return state
  //   })
  //   onToggleEditMode(id)
  // }

  const addNewLeague = () => {
    setLeagueInsertOpen(true)
  }
  const closeLeagueAdd = () => {
    setLeagueInsertOpen(false)
  }

  return (
    <>
      <Box
        sx={{
          width: '100vw',
          minHeight: '100vh',
          height: '100%',
          bgcolor: 'background.default',
          pb: 10,
        }}
      >
        <Navigation />
        <Container style={{ marginTop: 20 }}>
          <Paper>
            <ThemeButton onClick={() => addNewLeague()}>
              <AddIcon />
              Lisää liiga
            </ThemeButton>
            <Button onClick={() => setEditorOpen(true)}>Editor</Button>

            <DataGrid
              autoHeight
              pagination
              rows={rows}
              columns={dataGridColumns}
              components={{ Toolbar: GridToolbar }}
              initialState={{
                pagination: {
                  pageSize: 25,
                },
              }}
              onCellEditCommit={onCellEditCommit}
            />
          </Paper>
        </Container>
      </Box>
      <LeagueAdd open={leagueInsertOpen} onClose={closeLeagueAdd} />
      <EditorDialog onClose={handleEditorClose} open={editorOpen} value={selectedLeague?.regulations} />
    </>
  )
}

const withPlayers = compose(
  firebaseConnect(() => [
    {
      path: 'customers/lahiliiga/configs/leagues',
    },
    {
      path: 'customers/lahiliiga/configs/seasons',
    },

  ]),
  connect((state, props) => {
    const seasons = state.firebase.ordered?.customers?.lahiliiga?.configs?.seasons ? state.firebase.ordered.customers.lahiliiga.configs.seasons.map((r) => {
      return { id: r.key, ...r.value }
    }) : []
    const leagues = state.firebase.ordered?.customers?.lahiliiga?.configs?.leagues ? state.firebase.ordered.customers.lahiliiga.configs.leagues.map((r) => {
      return { id: r.key, ...r.value }
    }) : []
    return {
      leagues,
      seasons,
      auth: state.firebase.auth,
      update: props.firebase.update,
      push: props.firebase.push,
      set: props.firebase.set,
    }
  })
)

export default withPlayers(LeaguesPage)
