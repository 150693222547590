import { combineReducers } from 'redux'
import { firebaseReducer } from 'react-redux-firebase'
import { firestoreReducer } from 'redux-firestore'

function sportReducer(state = [], action) {
  switch (action.type) {
    case 'UPDATE_SPORT': {
      const sport = action.payload
      return {
        ...state,
        sport,
      }
    }
    case 'CLEAR_SPORT': {
      return {
        ...state,
        sport: null,
      }
    }
    case 'UPDATE_LEAGUE': {
      const league = action.payload
      return {
        ...state,
        league,
      }
    }
    case 'CLEAR_LEAGUE': {
      return {
        ...state,
        league: null,
      }
    }
    case 'UPDATE_GENDER': {
      const gender = action.payload
      return {
        ...state,
        gender,
      }
    }
    case 'CLEAR_GENDER': {
      return {
        ...state,
        gender: null,
      }
    }
    case 'UPDATE_SEASON': {
      const season = action.payload
      return {
        ...state,
        season,
      }
    }
    case 'CLEAR_SEASON': {
      return {
        ...state,
        season: null,
      }
    }
    case 'UPDATE_DIVISION': {
      const division = action.payload
      return {
        ...state,
        division,
      }
    }
    case 'CLEAR_DIVISION': {
      return {
        ...state,
        division: null,
      }
    }
    case 'UPDATE_GROUP': {
      const group = action.payload
      return {
        ...state,
        group,
      }
    }
    case 'CLEAR_GROUP': {
      return {
        ...state,
        group: null,
      }
    }

    default:
      return state
  }
}


const rootReducer = combineReducers({
  firestore: firestoreReducer,
  firebase: firebaseReducer,
  sport: sportReducer,
})

export default rootReducer
