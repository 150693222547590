import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import InputLabel from '@mui/material/InputLabel'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import OutlinedInput from '@mui/material/OutlinedInput'
import { withFirebase } from 'react-redux-firebase'
import { Grid } from '@mui/material'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'

function SeasonAdd({ open, onClose, firebase: { push } }) {
  const [openDialog, setOpenDialog] = React.useState(open)
  const [startyear, setStartyear] = React.useState(2024)
  const [endyear, setEndyear] = React.useState(2024)
  const [part, setPart] = React.useState('')

  React.useEffect(() => {
    setOpenDialog(open)
  }, [setOpenDialog, open])

  const handleDialogClose = () => {
    // setOpenDialog(false)
    setStartyear('')
    setEndyear(0)
    setPart('')
    onClose()
  }

  const onStartyearChange = (event) => {
    setStartyear(event.target.value)
  }

  const onEndyearChange = (event) => {
    setEndyear(event.target.value)
  }

  const onPartChange = (event) => {
    setPart(event.target.value)
  }


  const handleSeasonSave = () => {
    const doc = {
      startyear: startyear,
      endyear: endyear,
      part: part,
    }
    let key = ''
    let name = ''
    if (part) {
      key = startyear + '-' + part.toLowerCase().trim()
      name = startyear + ' ' + part.trim()
    } else {
      key = [startyear, endyear].join('-')
      name = String(startyear) + '/' + String(endyear).substring(2)
    }
    doc.key = key
    doc.name = name
    push('customers/lahiliiga/configs/seasons', doc)
    setEndyear(0)
    setStartyear(0)
    setPart('')
    onClose()
  }

  return (
    <Dialog fullWidth={true} maxWidth={'lg'} open={openDialog} onClose={handleDialogClose}>
      <DialogTitle>Lisää sarja</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <FormControl fullWidth sx={{ mt: 2, minWidth: 10 }}>
              <InputLabel htmlFor='outlined-adornment-amount'>Aloitusvuosi</InputLabel>
              <OutlinedInput
                id='outlined-adornment-amount'
                value={startyear}
                onChange={onStartyearChange}
                aria-describedby='outlined-weight-helper-text'
                inputProps={{
                  'aria-label': 'nimi',
                }}
                fullWidth
                label='Aloitusvuosi'
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth sx={{ mt: 2, minWidth: 10 }}>
              <InputLabel htmlFor='outlined-adornment-amount'>Lopetusvuosi</InputLabel>
              <OutlinedInput
                id='outlined-adornment-amount'
                value={endyear}
                onChange={onEndyearChange}
                aria-describedby='outlined-weight-helper-text'
                inputProps={{
                  'aria-label': 'nimi',
                }}
                fullWidth
                label='Lopetusvuosi'
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl sx={{ mt: 2, minWidth: 120 }}>
              <InputLabel htmlFor='outlined-adornment-amount'>Vuoden osa</InputLabel>
              <Select
                value={part}
                onChange={onPartChange}
                label='Laji'
                inputProps={{
                  name: 'max-width',
                  id: 'max-width',
                }}
              >
                <MenuItem value='Kevät'>Kevät</MenuItem>
                <MenuItem value='Kesä'>Kesä</MenuItem>
                <MenuItem value='Syksy'>Syksy</MenuItem>
              </Select>
            </FormControl>
          </Grid>

        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose}>Sulje</Button>
        <Button onClick={() => handleSeasonSave()}>Tallenna</Button>
      </DialogActions>
    </Dialog>
  )
}

export default withFirebase(SeasonAdd)
