import * as React from 'react'
import Box from '@mui/material/Box'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Chip from '@mui/material/Chip'
import { styled } from '@mui/material/styles'
import Navigation from './Navigation'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import { CircularProgress, Container, IconButton } from '@mui/material'
import config, { seasons, genders, divisions, p2rubberTemplate, p4rubberTemplate } from './config'
import { connect } from 'react-redux'
import { firebaseConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Switch from '@mui/material/Switch'
import InputLabel from '@mui/material/InputLabel'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import robin from 'roundrobin'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import DeleteIcon from '@mui/icons-material/Delete'
import TimePicker from '@mui/lab/TimePicker'
import DateTimePicker from '@mui/lab/DateTimePicker'
import TextField from '@mui/material/TextField'
import { format, parseISO } from 'date-fns'
import { fi } from 'date-fns/locale'
import OutlinedInput from '@mui/material/OutlinedInput'
import { getTieScores } from './Scores'
import { v4 as uuidv4 } from 'uuid'
import { filterFixture, filterGroup, filterTeam, filterLeagueTeams } from './StateFilters'

const getScoreSelects = () => {
  const scoreSelects = [22]
  let s = -10
  while (s <= 10) {
    scoreSelects.push({id: s, value: s})
    s += 0.5
  }
  return scoreSelects
}

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}))

const TieContainer = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
}))

function FixturesPage({
  auth,
  teams,
  push,
  update,
  dispatch,
  selections,
  fixtures,
  locations,
  players,
  remove,
  groups,
  league,
  leagueteams
}) {
  console.log('FixturesPage', league)
  const [group, setGroup] = React.useState(selections.group || '')
  const [gender, setGender] = React.useState(selections.gender || config.genders[0].key)
  const [division, setDivision] = React.useState(selections.division || config.divisions[0].key)
  const [season, setSeason] = React.useState(selections.season || config.seasons[0].key)
  const [note, setNote] = React.useState('')
  const [openCreate, setOpenCreate] = React.useState(false)
  const [openWait, setOpenWait] = React.useState(false)
  const [rubbers, setRubbers] = React.useState(false)
  const [roundrobintype, setRoundrobintype] = React.useState('once')
  const [tietype, setTietype] = React.useState('22p4p')
  const [finalToBePlayed, setFinalToBePlayed] = React.useState(false)
  const [relegationToBePlayed, setRelegationToBePlayed] = React.useState(false)
  const [promotionToBePlayed, setPromotionToBePlayed] = React.useState(false)
  const [openTie, setOpenTie] = React.useState(false)
  const [openTieCreate, setOpenTieCreate] = React.useState(false)
  const [selectedHomeTeamId, setSelectedHomeTeamId] = React.useState(null)
  const [selectedAwayTeamId, setSelectedAwayTeamId] = React.useState(null)

  const [openFreeTieCreate, setOpenFreeTieCreate] = React.useState(false)
  const [freeHomeTeam, setFreeHomeTeam] = React.useState(null)
  const [freeAwayTeam, setFreeAwayTeam] = React.useState(null)

  const [selectedLocationId, setSelectedLocationId] = React.useState(null)
  const [selectedRound, setSelectedRound] = React.useState(null)
  const [selectedDate, setSelectedDate] = React.useState(null)
  const [selectedFixtureId, setSelectedFixtureId] = React.useState(null)
  const [selectAllTeams, setSelectAllTeams] = React.useState(false)
  const [countToTable, setCountToTable] = React.useState(false)
  // const [anchorEl, setAnchorEl] = React.useState(null)
  // const menuOpen = Boolean(anchorEl)

  const handleOpenFreeTieCreate = (value) => {
    if (value) {
      setSelectAllTeams(true)
      setOpenFreeTieCreate(true)
    }
  }

  const clearSelections = () => {
    setSelectedFixtureId(null)
    setSelectedHomeTeamId(null)
    setSelectedAwayTeamId(null)
    setSelectedLocationId(null)
    setSelectedRound(null)
    setSelectedDate(null)
    setRubbers(null)
    setSelectAllTeams(false)
    setCountToTable(false)
  }

  const openPlayerMenu = (tieid) => {
    const _fixture = fixtures.find((f) => f.key === tieid)
    if (_fixture) {
      setSelectedFixtureId(_fixture.key)
      if (_fixture.value.rubbers) {
        setRubbers([..._fixture.value.rubbers])
      } else {
        setRubbers([])
      }
      setSelectedHomeTeamId(_fixture.value.home.id)
      setSelectedAwayTeamId(_fixture.value.away.id)
      setSelectedLocationId(_fixture.value.location)
      setSelectedRound(_fixture.value.round)
      setSelectAllTeams(_fixture.value.donotcount)
      setCountToTable(_fixture.value.donotcount)
      if (_fixture.value.time) setSelectedDate(_fixture.value.time)
    }
    setOpenTie(true)

    // setAnchorEl(event.currentTarget)
  }

  const handleCloseWait = () => {
    setOpenWait(false)
  }

  const handleTieClose = (event) => {
    clearSelections()
    setOpenTie(false)
  }

  const handleTieCreateClose = (event) => {
    clearSelections()
    setOpenTieCreate(false)
  }

  const handleFreeTieCreateClose = (event) => {
    clearSelections()
    setOpenFreeTieCreate(false)
  }


  const handleTieSave = (isNew) => {
    // console.log('handleTieSave', isNew)
    let uuid = uuidv4()
    let homeTeam = null
    let awayTeam = null
    if (openFreeTieCreate) {
      homeTeam = freeHomeTeam
      awayTeam = freeAwayTeam
    } else {
      homeTeam = leagueteams.find((t) => t.key === selectedHomeTeamId)
      awayTeam = leagueteams.find((t) => t.key === selectedAwayTeamId)
    }
    const updateFields = {
      time: selectedDate,
      round: selectedRound,
      location: selectedLocationId,
      rubbers,
      donotcount: countToTable
    }
    if (homeTeam) {
      updateFields.home = { id: homeTeam.key, name: homeTeam.value.name }
    } 
    if (awayTeam) {
      updateFields.away = { id: awayTeam.key, name: awayTeam.value.name }
    }

    if (note && note.length > 0) {
      updateFields.note = note
    }
    if (isNew && selectedFixtureId === null) {
      updateFields.round = selectedRound
      updateFields.gender = gender
      updateFields.sport = selections.sport.toLowerCase()
      updateFields.leagueid = league.key
      updateFields.group = group
      updateFields.division = division
      updateFields.season = season
      updateFields.rubbers = rubbers ? rubbers : []
      if (openFreeTieCreate) updateFields.donotcount = true
      updateFields.donotcount = countToTable

      update('customers/lahiliiga/tournaments/fixtures/' + uuid, updateFields)
      // console.log('Creating', uuid, updateFields)
      setSelectedFixtureId(uuid)
    } else {
      updateFields.leagueid = league.key
      update('customers/lahiliiga/tournaments/fixtures/' + selectedFixtureId, updateFields)
    }
    update('customers/lahiliiga/tournaments/fixturesupdated', { time: new Date().toISOString() })

    if (isNew) {
      setOpenTieCreate(false)
      openPlayerMenu(selectedFixtureId || uuid)
    } else {
      clearSelections()
      setOpenTie(false)
      setOpenTieCreate(false)
    }
  }

  const handleTieDelete = (tieid) => {
    remove('customers/lahiliiga/tournaments/fixtures/' + tieid)
    setSelectedFixtureId(null)
  }

  // const handleMenuClose = () => {
  //   setAnchorEl(null);
  // }

  const handleRubberTimeChange = (value, index) => {
    // const _fixture = fixtures.find((f) => f.key === selectedFixtureId)
    // const { rubbers } = _fixture.value
    rubbers[index].time = value
    update('customers/lahiliiga/tournaments/fixtures/' + selectedFixtureId, { rubbers })
  }

  const handleRubberCourtChange = (event, index) => {
    // const _fixture = fixtures.find((f) => f.key === selectedFixtureId)
    // const { rubbers } = _fixture.value
    rubbers[index].court = event.target.value
    update('customers/lahiliiga/tournaments/fixtures/' + selectedFixtureId, { rubbers })
  }

  const handleRubberPlayerChange = (event, index, isHomeTeam, playerIndex) => {
    // const _fixture = fixtures.find((f) => f.key === selectedFixtureId)
    // const { rubbers } = _fixture.value
    let name = ''
    if (isHomeTeam) {
      name = 'home' + playerIndex.toString()
    } else {
      name = 'away' + playerIndex.toString()
    }
    const _player = players.find((p) => p.id === event.target.value)
    rubbers[index][name] = { id: _player.id, name: [_player.firstname, _player.lastname].join(' ') }
    update('customers/lahiliiga/tournaments/fixtures/' + selectedFixtureId, { rubbers })
  }
 

  const handleRubberScoreChange = (event, index) => {
    if (event.target.value.indexOf('-') > -1 && event.target.value.indexOf(',') > -1) {
      // const _fixture = fixtures.find((f) => f.key === selectedFixtureId)
      // const { rubbers } = _fixture.value
      let _score = event.target.value
        .split(',')
        .map((set) => {
          const setSplit = set.split('-')
          if (setSplit.length > 1) {
            return { home: parseInt(set.split('-')[0].trim()), away: parseInt(set.split('-')[1].trim()) }
          }
          return null
        })
        .filter((x) => x)
        .filter((x) => !isNaN(x.home) && !isNaN(x.away))
      rubbers[index].score = _score
      rubbers[index].scorestring = event.target.value
      update('customers/lahiliiga/tournaments/fixtures/' + selectedFixtureId, { rubbers })
    }
  }

  const handleRubberStandingsScoreOverrideChange = (event, index, isHomeTeam) => {
    console.log('handleRubberStandingsScoreOverrideChange', event.target.value, index, isHomeTeam)
    let name = ''
    if (isHomeTeam) {
      name = 'homestandingoverride'
    } else {
      name = 'awaystandingoverride'
    }
    let isEmpty = event.target.value === ''
    if(isEmpty) {
      delete rubbers[index][name]
    } else {
      rubbers[index][name] = parseFloat(event.target.value)
    }
    update('customers/lahiliiga/tournaments/fixtures/' + selectedFixtureId, { rubbers })
  }

  const handleRoundChange = (event) => {
    setSelectedRound(event.target.value)
  }

  const handleHomeTeamChange = (event) => {
    setSelectedHomeTeamId(event.target.value)
  }

  const handleAwayTeamChange = (event) => {
    setSelectedAwayTeamId(event.target.value)
  }

  const handleFreeHomeTeamChange = (event) => {
    setFreeHomeTeam({ key: '', value: { name: event.target.value, id: '' } })
  }

  const handleFreeAwayTeamChange = (event) => {
    setFreeAwayTeam({ key: '', value: { name: event.target.value, id: '' } })
  }


  const handleLocationChange = (event) => {
    setSelectedLocationId(event.target.value)
  }

  const handleGroupChange = (event) => {
    setGroup(event.target.value)
  }

  const handleGenderChange = (event) => {
    setGender(event.target.value)
  }

  const handleNoteChange = (event) => {
    setNote(event.target.value)
  }

  const handleDivisionChange = (event) => {
    setDivision(event.target.value)
  }

  const handleSeasonChange = (event) => {
    setSeason(event.target.value)
  }

  const handleCreateClose = (event) => {
    setOpenCreate(false)
  }

  const handleRoundRobinTypeChange = (event) => {
    setRoundrobintype(event.target.value)
  }

  const handleTieTypeChange = (event) => {
    setTietype(event.target.value)
  }

  const handleFinalChange = (event) => {
    setFinalToBePlayed(event.target.checked)
  }

  const handleRelegationChange = (event) => {
    setRelegationToBePlayed(event.target.checked)
  }

  const handlePromotionChange = (event) => {
    setPromotionToBePlayed(event.target.checked)
  }

  const handleCreateGenerate = () => {
    const ties = []
    const schedule = robin(teams.length, teams)
    const extraRound = []
    if (finalToBePlayed)
      extraRound.push([
        { key: '', value: { name: 'Finalisti 1', id: '' } },
        { key: '', value: { name: 'Finalisti 2', id: '' } },
      ])
    if (relegationToBePlayed)
      extraRound.push([
        { key: '', value: { name: 'Karsija 1', id: '' } },
        { key: '', value: { name: 'Karsija 2', id: '' } },
      ])
    if (promotionToBePlayed)
      extraRound.push([
        { key: '', value: { name: 'Nousukarsija 1', id: '' } },
        { key: '', value: { name: 'Nousukarsija 2', id: '' } },
      ])
    const fullSchedule = []
    fullSchedule.push(...schedule)
    if (roundrobintype === 'twice') {
      const reverseSchedule = schedule.map((round) =>
        round.map((match) => {
          let r = []
          r.push(...match)
          r.reverse()
          return r
        })
      )
      fullSchedule.push(...reverseSchedule)
    }
    if (extraRound.length > 0) fullSchedule.push(extraRound)

    const _rubbers = []
    if (tietype === '22p4p') {
      _rubbers.push(JSON.parse(JSON.stringify(p2rubberTemplate)))
      _rubbers.push(JSON.parse(JSON.stringify(p2rubberTemplate)))
      _rubbers.push(JSON.parse(JSON.stringify(p4rubberTemplate)))
    } else if (tietype === '4p') {
      _rubbers.push(JSON.parse(JSON.stringify(p4rubberTemplate)))
    } else if (tietype === '2p') {
      _rubbers.push(JSON.parse(JSON.stringify(p2rubberTemplate)))
    }

    fullSchedule.map((round, index) => {
      round.map((tie) => {
        // console.log('tie', tie)
        ties.push({
          gender,
          season,
          division,
          group: selections.group,
          sport: selections.sport.toLowerCase(),
          round: index,
          home: {
            id: tie[1].key,
            name: tie[1].value.name,
          },
          away: {
            id: tie[0].key,
            name: tie[0].value.name,
          },
          rubbers: _rubbers,
          location: '',
          time: new Date(),
        })
        return null
      })
      return null
    })
    for (let i = 0; i < ties.length; i++) {
      // console.log('ties[i]', ties[i])
      push('customers/lahiliiga/tournaments/fixtures', ties[i])
    }
    setOpenCreate(false)
  }

  const addRubber = (singles) => {
    let _rs = []
    if (rubbers) {
      _rs = rubbers
    }
    if (singles) {
      _rs.push(JSON.parse(JSON.stringify(p2rubberTemplate)))
    } else {
      _rs.push(JSON.parse(JSON.stringify(p4rubberTemplate)))
    }
    // console.log('_rs', _rs)
    setRubbers([..._rs])
  }
  /* Renders */

  const renderSelectors = () => {
    // console.log(groups)
    return (
      <Item>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <Select
            labelId='gender'
            id='gender-simple-select'
            value={gender}
            onChange={handleGenderChange}
            inputProps={{ 'aria-label': 'Without label' }}
          >
            {genders.map((g) => (
              <MenuItem value={g.key}>{g.title}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <Select
            labelId='division'
            id='division-simple-select'
            value={division}
            onChange={handleDivisionChange}
            inputProps={{ 'aria-label': 'Without label' }}
          >
            {divisions.map((g) => (
              <MenuItem value={g.key}>{g.title}</MenuItem>
            ))}
          </Select>
        </FormControl>

        {groups.length > 0 ? (
          !groups.includes(group) ? (
            setGroup(groups[0])
          ) : (
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Select
                labelId='group'
                id='group-simple-select'
                value={group}
                onChange={handleGroupChange}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                {groups.map((g) => (
                  <MenuItem value={g}>{g}</MenuItem>
                ))}
              </Select>
            </FormControl>
          )
        ) : group !== null ? (
          setGroup(null)
        ) : null}
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <Select
            labelId='season'
            id='season-simple-select'
            value={season}
            onChange={handleSeasonChange}
            inputProps={{ 'aria-label': 'Without label' }}
          >
            {seasons.map((g) => (
              <MenuItem key={g.key} value={g.key}>
                {g.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Item>
    )
  }

  const renderTie = (tie) => {
    // console.log('tie', tie)
    const location = tie.value.location ? locations.find((l) => l.key === tie.value.location) : null
    let score = null
    if (tie.value.rubbers && tie.value.rubbers.length > 0 && tie.value.rubbers[0].score) {
      const { homepoints, awaypoints } = getTieScores(tie.value.rubbers, tie.value.sport)
      score = (
        <Typography ml={2} color={'lightgreen'} variant='subtitle1' component='span'>
          {homepoints} - {awaypoints}
        </Typography>
      )
    }
    return (
      <TieContainer sx={{ mb: 2 }}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} onClick={(event) => openPlayerMenu(tie.key)}>
                <Typography variant='h6' component='span'>
                  {tie.value.home ? tie.value.home.name : ''} - {tie.value.away ? tie.value.away.name : ''}
                </Typography>
                {score}
              </Grid>
              <Grid item xs={4} sm={2} md={2}>
                <Typography variant='body2' component='div'>
                  {tie.value.time
                    ? format(parseISO(tie.value.time), 'eee d.M.yyyy p', {
                        locale: fi,
                      })
                    : null}
                </Typography>
              </Grid>
              <Grid item xs={4} sm={2} md={2}>
                <Typography variant='body2' component='div'>
                  {location ? location.value.name : null} - Kierros {tie.value.round + 1}
                </Typography>
              </Grid>
              <Grid item xs={4} sm={2} md={2}>
                <Typography variant='body2' component='div'>
                  {/* Kierros {tie.value.round + 1}{' '} */}
                  <IconButton aria-label='Delete' onClick={() => handleTieDelete(tie.key)}>
                    <DeleteIcon />
                  </IconButton>
                </Typography>
              </Grid>
              {tie.value.note ? <Grid item xs={12}><Chip label={tie.value.note} size="small" color="warning"/></Grid> : null}
            </Grid>
          </AccordionSummary>
          <AccordionSummary>
            <Grid item>
              <TableContainer component={Paper}>
                <Table sx={{ border: 0 }} size='small' aria-label='a dense table'>
                  <TableBody>
                    {tie.value.rubbers
                      ? tie.value.rubbers
                          .sort((a, b) => a.order - b.order)
                          .map((r) => (
                            <TableRow sx={{ td: { border: 0 } }}>
                              <TableCell>
                                {r.court} - {r.time ? format(parseISO(r.time), 'p', { locale: fi }) : null}
                              </TableCell>
                              <TableCell align='right'>
                                {r.home1 && r.home1.id.length > 0
                                  ? r.type === '2p'
                                    ? r.home1.name + ' - ' + r.away1.name
                                    : r.home1.name + ' / ' + r.home2.name + ' - ' + r.away1.name + ' / ' + r.away2.name
                                  : r.type === '2p'
                                  ? 'Kaksinpeli'
                                  : 'Nelinpeli'}
                              </TableCell>
                              <TableCell>
                                {r.score
                                  ? r.score
                                      .map((set) => {
                                        return set.home + '-' + set.away
                                      })
                                      .join(', ')
                                  : null}
                              </TableCell>
                            </TableRow>
                          ))
                      : null}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </AccordionSummary>
        </Accordion>
      </TieContainer>
    )
  }

  const renderRubberEdits = (tieid) => {
    const _fixture = fixtures.find((f) => f.key === tieid)
    if (_fixture) {
      // console.log('_fixture', _fixture)
      const _homeTeam = _fixture ? leagueteams.find((t) => _fixture.value.home.id === t.key) : null
      const _awayTeam = _fixture ? leagueteams.find((t) => _fixture.value.away.id === t.key) : null
      const _homePlayers = _homeTeam
        ? players.filter((p) => _homeTeam.value.players && _homeTeam.value.players.includes(p.id))
        : []
      const _awayPlayers = _awayTeam
        ? players.filter((p) => _awayTeam.value.players && _awayTeam.value.players.includes(p.id))
        : []
      let location = selectedLocationId ? locations.find((l) => l.key === selectedLocationId) : null
      if (rubbers) {
        let _t = rubbers.map((rubber, index) => {
          return (
            <>
              <Divider variant='middle' sx={{ mt: 2 }} />
              <FormControl sx={{ mt: 2, minWidth: 120 }}>
                <TimePicker
                  value={rubber.time}
                  onChange={(newValue) => handleRubberTimeChange(newValue, index)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </FormControl>
              <FormControl sx={{ mt: 2, mr: 2, minWidth: 120 }}>
                <InputLabel>Kenttä</InputLabel>
                <Select value={rubber.court} onChange={(e) => handleRubberCourtChange(e, index)} label='Kenttä'>
                  {location
                    ? location.value.courts.map((t, index) => (
                        <MenuItem key={index} value={t}>
                          {t}
                        </MenuItem>
                      ))
                    : null}
                </Select>
              </FormControl>
              <FormControl sx={{ mt: 2, mr: 2, minWidth: 120 }}>
                <InputLabel>Koti</InputLabel>
                <Select
                  value={rubber.home1.id || null}
                  onChange={(e) => handleRubberPlayerChange(e, index, true, 1)}
                  label='Koti'
                  // inputProps={{
                  //   name: 'max-width',
                  //   id: 'max-width',
                  // }}
                >
                  {_homePlayers.map((t) => {
                    return (
                      <MenuItem key={t.id} value={t.id}>
                        {[t.firstname, t.lastname].join(' ')}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
              {rubber.type === '4p' ? (
                <FormControl sx={{ mt: 2, mr: 2, minWidth: 120 }}>
                  <InputLabel>Koti</InputLabel>
                  <Select
                    value={rubber.home2.id || null}
                    onChange={(e) => handleRubberPlayerChange(e, index, true, 2)}
                    label='Koti'
                    // inputProps={{
                    //   name: 'max-width',
                    //   id: 'max-width',
                    // }}
                  >
                    {_homePlayers.map((t) => {
                      return (
                        <MenuItem key={t.id} value={t.id}>
                          {[t.firstname, t.lastname].join(' ')}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              ) : null}
              {'  '}
              <FormControl sx={{ mt: 2, mr: 2, minWidth: 120 }}>
                <InputLabel>Vieras</InputLabel>
                <Select
                  value={rubber.away1.id || null}
                  onChange={(e) => handleRubberPlayerChange(e, index, false, 1)}
                  label='Vieras'
                  // inputProps={{
                  //   name: 'max-width',
                  //   id: 'max-width',
                  // }}
                >
                  {_awayPlayers.map((t) => {
                    return (
                      <MenuItem key={t.id} value={t.id}>
                        {[t.firstname, t.lastname].join(' ')}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
              {rubber.type === '4p' ? (
                <FormControl sx={{ mt: 2, mr: 2, minWidth: 120 }}>
                  <InputLabel>Vieras</InputLabel>
                  <Select
                    value={rubber.away2.id || null}
                    onChange={(e) => handleRubberPlayerChange(e, index, false, 2)}
                    label='Vieras'
                    // inputProps={{
                    //   name: 'max-width',
                    //   id: 'max-width',
                    // }}
                  >
                    {_awayPlayers.map((t) => {
                      return (
                        <MenuItem key={t.id} value={t.id}>
                          {[t.firstname, t.lastname].join(' ')}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              ) : null}
              <FormControl sx={{ mt: 2, mr: 2, minWidth: 10 }}>
                <InputLabel htmlFor='outlined-adornment-amount'>Tulos esim 6-2,6-1</InputLabel>
                <OutlinedInput
                  id='outlined-adornment-amount'
                  value={rubber.scorestring || null}
                  onChange={(e) => handleRubberScoreChange(e, index)}
                  aria-describedby='outlined-weight-helper-text'
                  inputProps={{
                    'aria-label': 'tulos',
                  }}
                  label='Tulos esim 6-2,6-1'
                />
              </FormControl>
              <FormControl sx={{ mt: 2, mr: 2, minWidth: 150 }}>
              <InputLabel>Kotipisteiden ylikirjoitus</InputLabel>
                  <Select
                  value={rubber.homestandingoverride || null}
                  onChange={(e) => handleRubberStandingsScoreOverrideChange(e, index, true)}
                    label='Kotipisteiden ylikirjoitus'
                  >
                  {getScoreSelects().map((t) => {
                      return (
                        <MenuItem key={t.id} value={t.id}>
                          {t.value}
                        </MenuItem>
                      )
                    })}
                  </Select>


                {/* <InputLabel htmlFor='outlined-adornment-amount'>Koti pisteiden ylikirjoitus</InputLabel>
                <OutlinedInput
                  id='outlined-adornment-amount'
                  value={rubber.homestandingoverride || null}
                  onChange={(e) => handleRubberStandingsScoreOverrideChange(e, index, true)}
                  aria-describedby='outlined-weight-helper-text'
                  inputProps={{
                    'aria-label': 'kotijoukkueensarjapisteidenylikirjoitus',
                  }}
                  label='Koti pisteiden ylikirjoitus'
                /> */}
              </FormControl>
              <FormControl sx={{ mt: 2, minWidth: 150 }}>
                <InputLabel>Vieraspisteiden ylikirjoitus</InputLabel>
              <Select
                  value={rubber.awaystandingoverride || null}
                  onChange={(e) => handleRubberStandingsScoreOverrideChange(e, index, false)}
                  label='Vieraspisteiden ylikirjoitus'

                  >
                  {getScoreSelects().map((t) => {
                      return (
                        <MenuItem key={t.id} value={t.id}>
                          {t.value}
                        </MenuItem>
                      )
                    })}
                  </Select>

{/* 
                <InputLabel htmlFor='outlined-adornment-amount'>Vieras pisteiden ylikirjoitus</InputLabel>
                <OutlinedInput
                  id='outlined-adornment-amount'
                  value={rubber.awaystandingoverride || null}
                  onChange={(e) => handleRubberStandingsScoreOverrideChange(e, index, false)}
                  aria-describedby='outlined-weight-helper-text'
                  inputProps={{
                    'aria-label': 'vierasjoukkueensarjapisteidenylikirjoitus',
                  }}
                  label='Vieras pisteiden ylikirjoitus'
                /> */}
              </FormControl>
            </>
          )
        })
        _t.push(
          <>
            <Divider variant='middle' sx={{ mt: 2 }} />
            <Button onClick={() => addRubber(true)}>Lisää kaksinpeli</Button>{' '}
            <Button onClick={() => addRubber(false)}>Lisää nelinpeli</Button>
          </>
        )
        return _t
      } else {
        return (
          <>
            <Divider variant='middle' sx={{ mt: 2 }} />
            <Button onClick={() => addRubber(true)}>Lisää kaksinpeli</Button>{' '}
            <Button onClick={() => addRubber(false)}>Lisää nelinpeli</Button>
          </>
        )
      }

      // } else if (rubbers) {
      //   let _t = rubbers.map((rubber, index) => {
      //     return (
      //       <>
      //         <Divider variant='middle' sx={{ mt: 2 }} />
      //         <FormControl sx={{ mt: 2, minWidth: 120 }}>
      //           <TimePicker
      //             value={rubber.time}
      //             onChange={(newValue) => handleRubberTimeChange(newValue, index)}
      //             renderInput={(params) => <TextField {...params} />}
      //           />
      //         </FormControl>
      //         <FormControl sx={{ mt: 2, mr: 2, minWidth: 120 }}>
      //           <InputLabel>Kenttä</InputLabel>
      //           <Select value={rubber.court} onChange={(e) => handleRubberCourtChange(e, index)} label='Kenttä'>
      //             {location
      //               ? location.value.courts.map((t, index) => (
      //                   <MenuItem key={index} value={t}>
      //                     {t}
      //                   </MenuItem>
      //                 ))
      //               : null}
      //           </Select>
      //         </FormControl>
      //         <FormControl sx={{ mt: 2, mr: 2, minWidth: 120 }}>
      //           <InputLabel>Koti</InputLabel>
      //           <Select
      //             value={rubber.home1.id || null}
      //             onChange={(e) => handleRubberPlayerChange(e, index, true, 1)}
      //             label='Koti'
      //             // inputProps={{
      //             //   name: 'max-width',
      //             //   id: 'max-width',
      //             // }}
      //           >
      //             {_homePlayers.map((t) => {
      //               return (
      //                 <MenuItem key={t.id} value={t.id}>
      //                   {[t.firstname, t.lastname].join(' ')}
      //                 </MenuItem>
      //               )
      //             })}
      //           </Select>
      //         </FormControl>
      //         {rubber.type === '4p' ? (
      //           <FormControl sx={{ mt: 2, mr: 2, minWidth: 120 }}>
      //             <InputLabel>Koti</InputLabel>
      //             <Select
      //               value={rubber.home2.id || null}
      //               onChange={(e) => handleRubberPlayerChange(e, index, true, 2)}
      //               label='Koti'
      //               // inputProps={{
      //               //   name: 'max-width',
      //               //   id: 'max-width',
      //               // }}
      //             >
      //               {_homePlayers.map((t) => {
      //                 return (
      //                   <MenuItem key={t.id} value={t.id}>
      //                     {[t.firstname, t.lastname].join(' ')}
      //                   </MenuItem>
      //                 )
      //               })}
      //             </Select>
      //           </FormControl>
      //         ) : null}
      //         {'  '}
      //         <FormControl sx={{ mt: 2, mr: 2, minWidth: 120 }}>
      //           <InputLabel>Vieras</InputLabel>
      //           <Select
      //             value={rubber.away1.id || null}
      //             onChange={(e) => handleRubberPlayerChange(e, index, false, 1)}
      //             label='Vieras'
      //             // inputProps={{
      //             //   name: 'max-width',
      //             //   id: 'max-width',
      //             // }}
      //           >
      //             {_awayPlayers.map((t) => {
      //               return (
      //                 <MenuItem key={t.id} value={t.id}>
      //                   {[t.firstname, t.lastname].join(' ')}
      //                 </MenuItem>
      //               )
      //             })}
      //           </Select>
      //         </FormControl>
      //         {rubber.type === '4p' ? (
      //           <FormControl sx={{ mt: 2, mr: 2, minWidth: 120 }}>
      //             <InputLabel>Vieras</InputLabel>
      //             <Select
      //               value={rubber.away2.id || null}
      //               onChange={(e) => handleRubberPlayerChange(e, index, false, 2)}
      //               label='Vieras'
      //               // inputProps={{
      //               //   name: 'max-width',
      //               //   id: 'max-width',
      //               // }}
      //             >
      //               {_awayPlayers.map((t) => {
      //                 return (
      //                   <MenuItem key={t.id} value={t.id}>
      //                     {[t.firstname, t.lastname].join(' ')}
      //                   </MenuItem>
      //                 )
      //               })}
      //             </Select>
      //           </FormControl>
      //         ) : null}
      //         <FormControl sx={{ mt: 2, minWidth: 10 }}>
      //           <InputLabel htmlFor='outlined-adornment-amount'>Tulos esim 6-2,6-1</InputLabel>
      //           <OutlinedInput
      //             id='outlined-adornment-amount'
      //             value={rubber.scorestring || null}
      //             onChange={(e) => handleRubberScoreChange(e, index)}
      //             aria-describedby='outlined-weight-helper-text'
      //             inputProps={{
      //               'aria-label': 'tulos',
      //             }}
      //             label='Tulos esim 6-2,6-1'
      //           />
      //         </FormControl>
      //       </>
      //     )
      //   })
      //   _t.push(<>
      //     <Divider variant='middle' sx={{ mt: 2 }} />
      //     <Button onClick={() => addRubber(true)}>Lisää kaksinpeli</Button>{' '}
      //     <Button onClick={() => addRubber(false)}>Lisää nelinpeli</Button>
      //   </>)
      //   return _t
      // } else {
      //   return (
      //     <>
      //       <Divider variant='middle' sx={{ mt: 2 }} />
      //       <Button onClick={() => addRubber(true)}>Lisää kaksinpeli</Button>{' '}
      //       <Button onClick={() => addRubber(false)}>Lisää nelinpeli</Button>
      //     </>
      //   )
    }
  }

  React.useEffect(() => {
    if (division) {
      dispatch({
        type: 'UPDATE_DIVISION',
        payload: division,
      })
    }
    if (gender) {
      dispatch({
        type: 'UPDATE_GENDER',
        payload: gender,
      })
    }
    if (season) {
      dispatch({
        type: 'UPDATE_SEASON',
        payload: season,
      })
    }
    if (group) {
      dispatch({
        type: 'UPDATE_GROUP',
        payload: group,
      })
    } else {
      dispatch({
        type: 'CLEAR_GROUP',
      })
    }
  }, [gender, division, season, group, dispatch, rubbers])

  return (
    <>
      <Box
        // sx={{
        //   display: 'flex',
        //   width: '100%',
        //   bgcolor: 'background.default',
        //   color: 'text.primary',
        // }}
        sx={{
          width: '100vw',
          minHeight: '100vh',
          height: '100%',
          bgcolor: 'background.default',
          pb: 10,
        }}
      >
       <Navigation />
        <Container>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12} sm={12} md={12}>
              {renderSelectors()}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={9} spacing={2}>
              {fixtures && fixtures.length > 0 ? (
                fixtures.map((f) => renderTie(f))
              ) : (
                <Grid item xs={12} sm={6} md={3}>
                  <Item>
                    Ei otteluohjelmaa
                    <Button variant='outlined' onClick={() => setOpenCreate(true)}>
                      Tee ohjelma
                    </Button>
                  </Item>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} md={3}>
              {teams
                ? teams
                    .filter((t) => t.value.gender === gender)
                    .map((t) => {
                      return <Item>{t.value.name}</Item>
                    })
                : null}
              <Item style={{ marginTop: 40 }}>
                <Button onClick={() => setOpenTieCreate(true)}>Lisää ottelu</Button>
              </Item>
              <Item style={{ marginTop: 40 }}>
                <Button onClick={() => handleOpenFreeTieCreate(true)}>Lisää karsinta/nousu/finaali aikataulu</Button>
              </Item>
            </Grid>
          </Grid>
        </Container>

        <Dialog fullWidth={true} maxWidth={'sm'} open={openCreate} onClose={handleCreateClose}>
          <DialogTitle>Luo otteluohjelma pohja</DialogTitle>
          <DialogContent>
            <DialogContentText>Voit luoda valmiiksi kaikki ottelut, joita voi käsin täydentää.</DialogContentText>
            <Box
              noValidate
              component='form'
              sx={{
                display: 'flex',
                flexDirection: 'column',
                m: 'auto',
                width: 'fit-content',
              }}
            >
              <FormControl sx={{ mt: 2, minWidth: 120 }}>
                <InputLabel htmlFor='max-width'>Sarjantyyppi</InputLabel>
                <Select
                  autoFocus
                  value={roundrobintype}
                  onChange={handleRoundRobinTypeChange}
                  label='Sarjantyyppi'
                  inputProps={{
                    name: 'max-width',
                    id: 'max-width',
                  }}
                >
                  <MenuItem value='once'>Yksinkertainen</MenuItem>
                  <MenuItem value='twice'>Kaksinkertainen</MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ mt: 2, minWidth: 120 }}>
                <InputLabel htmlFor='max-width'>Ottelut</InputLabel>
                <Select
                  value={tietype}
                  onChange={handleTieTypeChange}
                  label='Ottelutyyppi'
                  inputProps={{
                    name: 'max-width',
                    id: 'max-width',
                  }}
                >
                  <MenuItem value='22p4p'>2 x 2p, 1 x 4p</MenuItem>
                  <MenuItem value='4p'>4p</MenuItem>
                  <MenuItem value='2p'>2p</MenuItem>
                </Select>
              </FormControl>
              <FormControlLabel
                sx={{ mt: 1 }}
                control={<Checkbox checked={finalToBePlayed} onChange={handleFinalChange} />}
                label='Pelataan erillinen finaali'
              />
              <FormControlLabel
                sx={{ mt: 1 }}
                control={<Checkbox checked={promotionToBePlayed} onChange={handlePromotionChange} />}
                label='Pelataan nousukarsinta'
              />
              <FormControlLabel
                sx={{ mt: 1 }}
                control={<Checkbox checked={relegationToBePlayed} onChange={handleRelegationChange} />}
                label='Pelataan putoamiskarsinta'
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCreateClose}>Sulje</Button>
            <Button onClick={handleCreateGenerate}>Tallenna</Button>
          </DialogActions>
        </Dialog>

        <Dialog fullWidth={true} maxWidth={'xl'} open={openTie} onClose={handleTieClose}>
          <DialogTitle>Muokkaa ottelua ({selectedFixtureId}) ({league ? league.key: ''})</DialogTitle>
          <DialogContent>
            <FormControl sx={{ mt: 2, minWidth: 120 }}>
              <DateTimePicker
                label='Ottelupäivä'
                value={selectedDate}
                onChange={(newValue) => {
                  setSelectedDate(newValue)
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </FormControl>
            <FormControl sx={{ mt: 2, minWidth: 120 }}>
              <InputLabel htmlFor='max-width'>Paikka</InputLabel>
              <Select
                value={selectedLocationId}
                onChange={handleLocationChange}
                label='Paikka'
                inputProps={{
                  name: 'max-width',
                  id: 'max-width',
                }}
              >
                {locations.map((t) => (
                  <MenuItem key={t.key} value={t.key}>
                    {t.value.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ mt: 2, minWidth: 120 }}>
              <InputLabel htmlFor='max-width'>Kierros</InputLabel>
              <Select
                value={selectedRound}
                onChange={handleRoundChange}
                label='Kierros'
                inputProps={{
                  name: 'max-width',
                  id: 'max-width',
                }}
              >
                {[...Array(10).keys()].map((t) => (
                  <MenuItem key={t} value={t}>
                    {t + 1}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ mt: 2, minWidth: 120 }}>
              <InputLabel htmlFor='max-width'>Kotijoukkue</InputLabel>
              <Select
                value={selectedHomeTeamId}
                onChange={handleHomeTeamChange}
                label='Kotijoukkue'
                inputProps={{
                  name: 'max-width',
                  id: 'max-width',
                }}
              >
                {selectAllTeams ? leagueteams.map((t) => (
                  <MenuItem key={t.key} value={t.key}>
                    {t.value.name}
                  </MenuItem>
                )) :
                teams.map((t) => (
                  <MenuItem key={t.key} value={t.key}>
                    {t.value.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ mt: 2, minWidth: 120 }}>
              <InputLabel htmlFor='max-width'>Vierasjoukkue</InputLabel>
              <Select
                value={selectedAwayTeamId}
                onChange={handleAwayTeamChange}
                label='Vierasjoukkue'
                inputProps={{
                  name: 'max-width',
                  id: 'max-width',
                }}
              >
                {selectAllTeams ? leagueteams.map((t) => (
                  <MenuItem key={t.key} value={t.key}>
                    {t.value.name}
                  </MenuItem>
                )) : teams.map((t) => (
                  <MenuItem key={t.key} value={t.key}>
                    {t.value.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ mt: 2, minWidth: 120 }}>
            <TextField
                fullWidth
                id='note'
                name='note'
                label='Note'
                value={note || ''}
                onChange={handleNoteChange}
              />
            </FormControl>
            <FormControl sx={{ mt: 2, ml: 2, minWidth: 120 }}>
              <FormControlLabel control={<Switch checked={selectAllTeams} onChange={(e) => setSelectAllTeams(e.target.checked)} color="success" size="small" />} label="Valitse kaikista sarjan joukkueista" />
              <FormControlLabel control={<Switch checked={countToTable} onChange={(e) => setCountToTable(e.target.checked)} color="success" size="small" />} label="Älä laske sarjataulukkoon" />
            </FormControl>
            {renderRubberEdits(selectedFixtureId)}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleTieClose}>Sulje</Button>
            <Button onClick={() => handleTieSave()}>Tallenna</Button>
          </DialogActions>
        </Dialog>

        <Dialog fullWidth={true} maxWidth={'xl'} open={openTieCreate} onClose={handleTieCreateClose}>
          <DialogTitle>Lisää ottelu</DialogTitle>
          <DialogContent>
            <FormControl sx={{ mt: 2, mr: 2, minWidth: 120 }}>
              <DateTimePicker
                label='Ottelupäivä'
                value={selectedDate}
                onChange={(newValue) => {
                  setSelectedDate(newValue)
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </FormControl>
            <FormControl sx={{ mt: 2, mr: 2, minWidth: 120 }}>
              <InputLabel htmlFor='max-width'>Paikka</InputLabel>
              <Select
                value={selectedLocationId || ''}
                onChange={handleLocationChange}
                label='Paikka'
                inputProps={{
                  name: 'max-width',
                  id: 'max-width',
                }}
              >
                {locations.map((t) => (
                  <MenuItem key={t.key} value={t.key}>
                    {t.value.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ mt: 2, mr: 2, minWidth: 120 }}>
              <InputLabel htmlFor='max-width'>Kierros</InputLabel>
              <Select
                value={selectedRound || ''}
                onChange={handleRoundChange}
                label='Kierros'
                inputProps={{
                  name: 'max-width',
                  id: 'max-width',
                }}
              >
                {[...Array(10).keys()].map((t) => (
                  <MenuItem key={t} value={t}>
                    {t + 1}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ mt: 2, mr: 2, minWidth: 120 }}>
              <InputLabel htmlFor='max-width'>Kotijoukkue</InputLabel>
              <Select
                value={selectedHomeTeamId || ''}
                onChange={handleHomeTeamChange}
                label='Kotijoukkue'
                inputProps={{
                  name: 'max-width',
                  id: 'max-width',
                }}
              >
                {teams.map((t) => (
                  <MenuItem key={t.key} value={t.key}>
                    {t.value.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ mt: 2, mr: 2, minWidth: 120 }}>
              <InputLabel htmlFor='max-width'>Vierasjoukkue</InputLabel>
              <Select
                value={selectedAwayTeamId || ''}
                onChange={handleAwayTeamChange}
                label='Vierasjoukkue'
                inputProps={{
                  name: 'max-width',
                  id: 'max-width',
                }}
              >
                {teams.map((t) => (
                  <MenuItem key={t.key} value={t.key}>
                    {t.value.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {renderRubberEdits()}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleTieCreateClose}>Sulje</Button>
            <Button onClick={() => handleTieSave(true)}>Tallenna</Button>
          </DialogActions>
        </Dialog>

        <Dialog fullWidth={true} maxWidth={'xl'} open={openFreeTieCreate} onClose={handleFreeTieCreateClose}>
          <DialogTitle>Lisää ottelu</DialogTitle>
          <DialogContent>
            <FormControl sx={{ mt: 2, mr: 2, minWidth: 120 }}>
              <DateTimePicker
                label='Ottelupäivä'
                value={selectedDate}
                onChange={(newValue) => {
                  setSelectedDate(newValue)
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </FormControl>
            <FormControl sx={{ mt: 2, mr: 2, minWidth: 120 }}>
              <InputLabel htmlFor='max-width'>Paikka</InputLabel>
              <Select
                value={selectedLocationId || ''}
                onChange={handleLocationChange}
                label='Paikka'
                inputProps={{
                  name: 'max-width',
                  id: 'max-width',
                }}
              >
                {locations.map((t) => (
                  <MenuItem key={t.key} value={t.key}>
                    {t.value.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ mt: 2, mr: 2, minWidth: 120 }}>
              <InputLabel htmlFor='max-width'>Kierros</InputLabel>
              <Select
                value={selectedRound || ''}
                onChange={handleRoundChange}
                label='Kierros'
                inputProps={{
                  name: 'max-width',
                  id: 'max-width',
                }}
              >
                {[...Array(10).keys()].map((t) => (
                  <MenuItem key={t} value={t}>
                    {t + 1}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ mt: 2, mr: 2, minWidth: 120 }}>
              <TextField
                fullWidth
                id='freehome'
                name='freehome'
                label='Kotijoukkue'
                value={freeHomeTeam?.value?.name || ''}
                onChange={handleFreeHomeTeamChange}
              />
            </FormControl>
            <FormControl sx={{ mt: 2, mr: 2, minWidth: 120 }}>
            <TextField
                fullWidth
                id='freeaway'
                name='freeaway'
                label='Vierasjoukkue'
                value={freeAwayTeam?.value?.name || ''}
                onChange={handleFreeAwayTeamChange}
              />
            </FormControl>
            <FormControl sx={{ mt: 2, minWidth: 120 }}>
            <TextField
                fullWidth
                id='note'
                name='note'
                label='Note'
                value={note || ''}
                onChange={handleNoteChange}
              />
            </FormControl>
            <FormControl sx={{ mt: 2, ml: 2, minWidth: 120 }}>
              <FormControlLabel control={<Switch checked={countToTable} onChange={(e) => setCountToTable(e.target.checked)} color="success" size="small" />} label="Älä laske sarjataulukkoon" />
            </FormControl>

            {renderRubberEdits()}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleFreeTieCreateClose}>Sulje</Button>
            <Button onClick={() => handleTieSave(true)}>Tallenna</Button>
          </DialogActions>
        </Dialog>
        

        <Dialog fullWidth={false} maxWidth={'sm'} open={openWait} onClose={handleCloseWait}>
          <DialogTitle></DialogTitle>
          <DialogContent>
            <DialogContentText>
              <CircularProgress />
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </Box>
    </>
  )
}

const withTeams = compose(
  firebaseConnect(() => [
    {
      path: 'customers/lahiliiga/tournaments/teams',
    },
    {
      path: 'customers/lahiliiga/tournaments/fixtures',
    },
    {
      path: 'customers/lahiliiga/locations',
    },
    {
      path: 'customers/lahiliiga/players',
    },
  ]),
  connect((state, props) => {
    console.log('state', state.sport)
    if (
      state.firebase.ordered.customers &&
      state.firebase.ordered.customers.lahiliiga &&
      state.firebase.ordered.customers.lahiliiga.locations &&
      state.firebase.ordered.customers.lahiliiga.players &&
      state.firebase.ordered.customers.lahiliiga.tournaments &&
      state.firebase.ordered.customers.lahiliiga.tournaments.teams &&
      // state.firebase.ordered.customers.lahiliiga.tournaments.fixtures &&
      state.sport.sport &&
      state.sport.gender &&
      state.sport.season &&
      state.sport.division
    ) {
      const _groups = state.firebase.ordered.customers.lahiliiga.tournaments.teams
        .filter((t) => filterGroup(t, state))
        .map((t) => t.value.group)
        .filter((v, i, a) => a.indexOf(v) === i)
        .filter((x) => x)
        .sort()

      const _teams = state.firebase.ordered.customers.lahiliiga.tournaments.teams.filter((t) => filterTeam(t, state))
      const _leagueteams = state.firebase.ordered.customers.lahiliiga.tournaments.teams.filter((t) => filterLeagueTeams(t, state))

      let _fixtures = []
      if (state.firebase.ordered.customers.lahiliiga.tournaments.fixtures) {
        _fixtures = state.firebase.ordered.customers.lahiliiga.tournaments.fixtures.filter((t) => filterFixture(t, state))
        _fixtures.sort((a, b) => {
          if (a.value.time && b.value.time) {
            return parseISO(a.value.time) - parseISO(b.value.time)
          } else if (a.value.time) {
            return -1
          } else if (b.value.time) {
            return 1
          } else {
            return a.value.round - a.value.round
          }
        })
      }

      return {
        players: state.firebase.ordered.customers.lahiliiga.players.map((r) => {
          return { id: r.key, ...r.value }
        }),
        fixtures: _fixtures,
        teams: _teams,
        groups: _groups,
        auth: state.firebase.auth,
        update: props.firebase.update,
        push: props.firebase.push,
        dispatch: props.firebase.dispatch,
        remove: props.firebase.remove,
        selections: state.sport,
        locations: state.firebase.ordered.customers.lahiliiga.locations,
        sport: state.sport.sport.toLowerCase(),
        league: state.sport.league,
        leagueteams: _leagueteams
      }
    } else {
      return {
        players: [],
        fixtures: [],
        teams: [],
        leagueteams: [],
        groups: [],
        auth: state.firebase.auth,
        update: props.firebase.update,
        push: props.firebase.push,
        dispatch: props.firebase.dispatch,
        remove: props.firebase.remove,
        selections: state.sport,
        locations: [],
        league: null,
      }
    }
  })
)

export default withTeams(FixturesPage)
