import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import { useHistory } from 'react-router'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { styled } from '@mui/material/styles'
import Brightness4Icon from '@mui/icons-material/Brightness4'
import Brightness7Icon from '@mui/icons-material/Brightness7'
import { ColorModeContext } from './theme-context'
import { useTheme } from '@mui/material/styles'
import { useCookies } from 'react-cookie'
import { firebaseConnect } from 'react-redux-firebase'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { connect } from 'react-redux'
import { compose } from 'redux'

const classes = {
  title: {
    lineHeight: '30px',
    fontSize: '18px',
    borderRadius: '3px',
    textTransform: 'none',
    color: 'inherit',
    padding: '8px 16px',
    letterSpacing: 'unset',
    '&:hover,&:focus': {
      color: 'inherit',
      background: 'transparent',
    },
  },
}

const lajit = ['LAJI', 'TENNIS', 'PADEL']
const routes = [
  { name: 'OTTELUT JA TULOKSET', path: '/fixtures' },
  { name: 'SARJATAULUKKO', path: '/standings' },
  { name: 'JOUKKUEET', path: '/teams' },
  { name: 'PELAAJAT', path: '/players' },
  { name: 'SARJAT', path: '/leagues' },
  { name: 'KAUDET', path: '/seasons' },
  { name: 'INFOT', path: '/regulations' },
]

const ThemeButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.secondary,
}))

function Navigation({ leagues,  dispatch }) {
  const history = useHistory()
  
  const theme = useTheme()
  const brandComponent = (
    <ThemeButton className={classes.title} onClick={() => history.push('/')}>
      Lähiliiga
    </ThemeButton>
  )
  const [anchorElSmall, setAnchorElSmall] = React.useState(null)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [anchorElLeague, setAnchorElLeague] = React.useState(null)
  const [selectedIndex, setSelectedIndex] = React.useState(0)
  const [selectedLeague, setSelectedLeague] = React.useState(null)
  const openLaji = Boolean(anchorEl)
  const openLeague = Boolean(anchorElLeague)
  const openSmall = Boolean(anchorElSmall)
  const colorMode = React.useContext(ColorModeContext)
  const [cookies, setCookie] = useCookies(['sport', 'mode', 'leagueid', 'favoriteteam'])

  if (cookies.sport) {
    const index = lajit.findIndex((x) => x === cookies.sport)
    if (selectedIndex !== index) {
      setSelectedIndex(index)
    }
  }


  if (cookies.leagueid) {
    if (leagues && leagues.length > 0) {
      const league = leagues.find((x) => x.key === cookies.leagueid)
      // console.log('league', league, 'selectedLeague', selectedLeague, 'leagues', leagues)
      if (!selectedLeague || selectedLeague.key !== league.key) {
        setSelectedLeague(league)
      }
    }
  } else {
    // console.log('cookie', selectedLeague)
    if (selectedLeague !== null) {
      setSelectedLeague(null)
    }
  }

  const handleLajiClick = (event, index) => {
    setAnchorEl(event.currentTarget)
    setSelectedIndex(index)
  }
  
  const handleSmallClick = (event, index) => {
    setAnchorElSmall(event.currentTarget)
  }

  const handleLeagueClick = (event, index) => {
    // console.log('handleLeagueClick', event.currentTarget, index)
    setAnchorElLeague(event.currentTarget)
  }

  const handleLeagueClose = () => {
    setAnchorElLeague(null)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleCloseSmall = () => {
    setAnchorElSmall(null)
  }

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index)
    setCookie('sport', lajit[index], { path: '/', maxAge: 60000, secure: true })
    setAnchorEl(null)
  }

  const handleLeagueMenuItemClick = (event, leagueid) => {
    const league = leagues.find((x) => x.key === leagueid)
    if (league) {
      setSelectedLeague(league)
      setCookie('leagueid', leagueid, { path: '/', maxAge: 60000, secure: true })
      setAnchorElLeague(null)
    }
  }

  const toggleMode = () => {
    const mode = theme.palette.mode === 'dark' ? 'light' : 'dark'
    setCookie('mode', mode, { path: '/', maxAge: 60000, secure: true })
    colorMode.toggleColorMode()
  }

  React.useEffect(() => {
    dispatch({
      type: 'UPDATE_SPORT',
      payload: lajit[selectedIndex],
    })
    // setGlobalState(selectedIndex)
  },[selectedIndex, dispatch])

  React.useEffect(() => {
    if (selectedLeague) {
      console.log('selectedLeague', selectedLeague)
      dispatch({
        type: 'UPDATE_LEAGUE',
        payload: selectedLeague,
      })
      dispatch({
        type: 'UPDATE_SPORT',
        payload: selectedLeague.value.sport.toUpperCase(),
      })
      const index = lajit.findIndex((x) => x === selectedLeague.value.sport.toUpperCase())
      setSelectedIndex(index)
      setCookie('sport', lajit[index], { path: '/', maxAge: 60000, secure: true })
  
    }
  }, [selectedLeague, dispatch,setSelectedIndex,setCookie ])


  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position='fixed'>
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <div>
            {brandComponent}
            <ThemeButton
                id='league-positioned-button'
                aria-controls='league-positioned-menu'
                aria-haspopup='true'
                aria-expanded={openLeague ? 'true' : undefined}
                onClick={handleLeagueClick}
                endIcon={<ExpandMoreIcon /> }
              >
                {selectedLeague ? selectedLeague.value.name : 'Liiga'}
              </ThemeButton>
              <Menu
                id='league-positioned-menu'
                aria-labelledby='league-positioned-button'
                anchorEl={anchorElLeague}
                open={openLeague}
                onClose={handleLeagueClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                {leagues.map((option, index) => (
                  <MenuItem
                    key={option.key}
                    selected={selectedLeague ? option.key === selectedLeague.key : false}
                    onClick={(event) => handleLeagueMenuItemClick(event, option.key)}
                  >
                    {option.value.name}
                  </MenuItem>
                ))}
              </Menu>


            <ThemeButton
              id='demo-positioned-button'
              aria-controls='demo-positioned-menu'
              aria-haspopup='true'
              aria-expanded={openLaji ? 'true' : undefined}
              onClick={handleLajiClick}
            >
              {lajit[selectedIndex]}
            </ThemeButton>
            <Menu
              id='demo-positioned-menu'
              aria-labelledby='demo-positioned-button'
              anchorEl={anchorEl}
              open={openLaji}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              {lajit.map((option, index) => (
                <MenuItem
                  key={option}
                  disabled={index === 0}
                  selected={index === selectedIndex}
                  onClick={(event) => handleMenuItemClick(event, index)}
                >
                  {option}
                </MenuItem>
              ))}
            </Menu>
          </div>
          {routes.map((r, i) => {
            return (
              <ThemeButton
                key={r.name}
                onClick={() => history.push(r.path)}
                variant='text'
                sx={{ display: { sm: 'block', xs: 'none' } }}
              >
                {r.name}
              </ThemeButton>
            )
          })}
          <div style={{ color: 'black' }}>
            <IconButton
              sx={{ ml: 1 }}
              onClick={toggleMode}
              color={theme.palette.mode === 'dark' ? 'primary' : 'inherit'}
            >
              {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
            </IconButton>
          </div>
          <div>v. 1.1.21</div>
          <div style={{ color: 'black' }}>
            <Menu
              id='smallmenu'
              aria-labelledby='smallmenu'
              anchorEl={anchorElSmall}
              open={openSmall}
              onClose={handleCloseSmall}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              {routes.map((r, i) => {
                return (
                  <MenuItem key={r.name} onClick={() => history.push(r.path)}>
                    {r.name}
                  </MenuItem>
                )
              })}
            </Menu>
            <IconButton
              size='large'
              edge='start'
              color={theme.palette.mode === 'dark' ? 'primary' : 'inherit'}
              aria-label='menu'
              sx={{ mr: 2, display: { sm: 'none', xs: 'block' } }}
              onClick={handleSmallClick}
            >
              <MenuIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <Toolbar/>
    </Box>
  )
}
const withLeagues = compose(
  firebaseConnect(() => [
    {
      path: 'customers/lahiliiga/configs/leagues',
    },
  ]),
  connect((state, props) => {
    if (
      state.firebase.ordered.customers &&
      state.firebase.ordered.customers.lahiliiga &&
      state.firebase.ordered.customers.lahiliiga.configs
    ) {
      // console.log(state.firebase.ordered.customers.lahiliiga.configs.leagues)
      return {
        leagues: state.firebase.ordered.customers.lahiliiga.configs.leagues,
        dispatch: props.firebase.dispatch,
      }
    } else {
      return {
        leagues: [],
        dispatch: props.firebase.dispatch,
      }
    }
  })
)

export default withLeagues(Navigation)