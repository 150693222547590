import React, { useRef } from 'react'
import { Editor } from '@tinymce/tinymce-react'
import { Button, Grid } from '@mui/material'

export default function TinyEditor({ onClose, value }) {
  const editorRef = useRef(null)
  const onChangeContent = () => {
    if (editorRef.current) {
      onClose(editorRef.current.getContent())
    }
  }
  return (
    <>
      <Editor
        tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
        onInit={(evt, editor) => (editorRef.current = editor)}
        initialValue={value || ''} 
        init={{
          height: 800,
          // width: 1200,
          menubar: false,
          plugins: [
            'advlist',
            'autolink',
            'lists',
            'link',
            'image',
            'charmap',
            'anchor',
            'searchreplace',
            'visualblocks',
            'code',
            'fullscreen',
            'insertdatetime',
            'media',
            'table',
            'preview',
            'help',
            'wordcount',
          ],
          toolbar:
            'undo redo | blocks | ' +
            'bold italic forecolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | help',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
        }}
      />
      <Grid container justifyContent='flex-end'>
        <Grid item>
          <Button color='primary' onClick={onChangeContent}>
            {'Valmis'}
          </Button>
        </Grid>
      </Grid>
    </>
  )
}
