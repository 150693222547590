import * as React from 'react'
import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Navigation from './Navigation'
import { Container } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

import { connect } from 'react-redux'
import { firebaseConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import PlayerAdd from './PlayerAdd'

const dataGridColumns = [
  { field: 'firstname', headerName: 'Etunimi', width: 150, editable: true },
  { field: 'lastname', headerName: 'Sukunimi', width: 150, editable: true },
  { field: 'gender', headerName: 'Sukupuoli', width: 150, editable: true },
  { field: 'phone', headerName: 'Puhelin', width: 150, editable: true },
  { field: 'email', headerName: 'Email', width: 150, editable: true },
  { field: 'teams', headerName: 'Joukkueet', width: 400, editable: false },
]

const ThemeButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.secondary,
}))

function PlayersPage({ players, push, update }) {
  const [rows, setRows] = React.useState(players || [])
  const [playerInsertOpen, setPlayerInsertOpen] = React.useState(false)

  const onCellEditCommit = (params) => {
    const updateRow = { [params.field]: params.value }
    update('customers/lahiliiga/players/' + params.id, updateRow)
  }

  React.useEffect(() => {
    setRows(players)
  }, [players])

  const addNewPlayer = () => {
    setPlayerInsertOpen(true)
  }
  const closePlayerAdd = () => {
    setPlayerInsertOpen(false)
  }

  return (
    <>
      <Box
        // sx={{
        //   display: 'flex',
        //   width: '100%',
        //   alignItems: 'center',
        //   justifyContent: 'center',
        //   bgcolor: 'background.default',
        //   color: 'text.primary',
        //   borderRadius: 0,
        //   p: 0,
        // }}
        sx={{
          width: '100vw',
          minHeight: '100vh',
          height: '100%',
          bgcolor: 'background.default',
          pb: 10,
        }}
      >
        <Navigation />
        <Container style={{ marginTop: 20 }}>
          <Paper>
            <ThemeButton onClick={() => addNewPlayer()}>
              <AddIcon />
              Lisää pelaaja
            </ThemeButton>

            <DataGrid
              autoHeight
              pagination
              rows={rows}
              columns={dataGridColumns}
              components={{ Toolbar: GridToolbar }}
              initialState={{
                pagination: {
                  pageSize: 25,
                },
              }}
              onCellEditCommit={onCellEditCommit}
            />
          </Paper>
        </Container>
      </Box>
      <PlayerAdd open={playerInsertOpen} onClose={closePlayerAdd} />
    </>
  )
}

const findPlayerTeams = (teams, playerid) => {
  if (teams) {
    return teams.filter((t) => {
      if (t.value && t.value.players && Array.isArray(t.value.players)) {
        return t.value.players.includes(playerid)
      } else {
        return false
      }
    })
  } else {
    return teams
  }
}

const withPlayers = compose(
  firebaseConnect(() => [
    {
      path: 'customers/lahiliiga/players',
    },
    {
      path: 'customers/lahiliiga/tournaments/teams',
    },
  ]),
  connect((state, props) => {
    if (
      state.firebase.ordered?.customers?.lahiliiga?.players &&
      state.firebase.ordered?.customers?.lahiliiga?.tournaments?.teams
    ) {
      let teams = state.firebase.ordered.customers.lahiliiga.tournaments.teams.filter(
        (t) => t.value.leagueid === state.sport.league.key
      )
      // console.log(state.firebase.ordered.customers.lahiliiga.tournaments.teams.length, teams)
      return {
        players: state.firebase.ordered.customers.lahiliiga.players.map((r) => {
          const playerTeams = findPlayerTeams(teams, r.key).map((t) => t.value.name + ' (' + t.value.gender + ')')
          return { id: r.key, ...r.value, teams: playerTeams.join(', ') }
        }),
        auth: state.firebase.auth,
        update: props.firebase.update,
        push: props.firebase.push,
        set: props.firebase.set,
      }
    } else {
      return {
        players: [],
        auth: state.firebase.auth,
        update: props.firebase.update,
        push: props.firebase.push,
        set: props.firebase.set,
      }
    }
  })
)

export default withPlayers(PlayersPage)
