import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TinyEditor from './TinyEditor';

export default function EditorDialog({ open, onClose, title, value }) {

  return (
    <div>
      <Dialog open={open} onClose={onClose} maxWidth={'lg'}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <TinyEditor onClose={onClose} value={value} />
        </DialogContent>
      </Dialog>
    </div>
  );
}