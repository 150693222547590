export const firebaseConfig = {
  apiKey: 'AIzaSyBPqn6usEbZYVVADkNfASJY5Ag95kgQOkE',
  authDomain: 'falcon-tournament.firebaseapp.com',
  databaseURL: 'https://falcon-tournament-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'falcon-tournament',
  storageBucket: 'falcon-tournament.appspot.com',
  messagingSenderId: '272757856416',
  appId: '1:272757856416:web:77ce8d9ec2b09ef759d1c5',
  measurementId: 'G-FDJLBG3YTT',
}

export const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: false,
  enableClaims: true,
}

export const seasons = [
  { key: '20212022', title: '2021/22', order: 1 },
  { key: '2022-kesa', title: '2022 Kesä', order: 2 },
  { key: '2022-syksy', value: '2022 Syksy', order: 3 },
  { key: '2022-kevat', title: '2022 Kevät', order: 4 },
  { key: '20222023', title: '2022/23', order: 5 },
  { key: '2023-kesä', title: '2023 Kesä', order: 6 },
  { key: '2023-syksy', title: '2023 Syksy', order: 7 },
  { key: 'syksy-2023', title: '2023 Syksy', order: 7 },
  { key: '20232024', title: '2023/24', order: 8 },
  { key: '2024-kevät', title: '2024 Kevät', order: 9 },
  { key: 'kevät-2024', title: '2024 Kevät', order: 9 },
  { key: '2024-kesä', title: '2024 Kesä', order: 10 },
  { key: '2024-syksy', title: '2024 Syksy', order: 11 },
  { key: '20242025', title: '2024/25', order: 12 },
]

export const genders = [
  { key: 'men', title: 'Miehet' },
  { key: 'women', title: 'Naiset' },
  { key: 'mixed', title: 'Mixed' },
  { key: 'open', title: 'Avoin' },
]

export const sports = [
  { key: 'tennis', title: 'Tennis' },
  { key: 'padel', title: 'Padel' },
  { key: 'salibandy', title: 'Salibandy' },
]

export const divisions = [
  { key: '1', title: 'Mestaruussarja' },
  { key: '2', title: '1. divisioona' },
  { key: '3', title: '2. divisioona' },
  { key: '4', title: '3. divisioona' },
  { key: '99', title: 'Ei sarjatasoa' },
]

export const pointrules = [
  { sport: 'tennis', rubbers: 3, setwin: 1, setdraw: 0.5 },
  { sport: 'tennis', rubbers: 1, setwin: 1, setdraw: 0, clearwin: 1, lastsetgames: 1 },
  { sport: 'padel', rubbers: 1, setwin: 1, setdraw: 0.5, clearwin: 0, lastsetgames: 0 },
]

export const p2rubberTemplate = {
  home1: { name: '', id: '' },
  away1: { name: '', id: '' },
  court: '',
  score: [],
  type: '2p',
}
export const p4rubberTemplate = {
  home1: { name: '', id: '' },
  away1: { name: '', id: '' },
  home2: { name: '', id: '' },
  away2: { name: '', id: '' },
  court: '',
  score: [],
  type: '4p',
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  firebaseConfig,
  rrfConfig,
  seasons,
  genders,
  sports,
  divisions,
  pointrules,
  p2rubberTemplate,
  p4rubberTemplate,
}
